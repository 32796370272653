// =================
// Contributors List
// =================

.ContributorsList-list {
  .Section &.Wrapper {
    margin-top: -45px;
    padding-top: 0;
  }
}

.ContributorsList-container{
  padding: 25px;
  background-color: $color-white;

  @media($mq-from-tablet) {
    padding: 50px;
  }
}

.ContributorsList-letter {
  margin-bottom: 10px;
  @include font-bold($font-family: $font-family-title);
  color: $color-primary;
  font-size: rem-calc(33);

  @media($mq-tablet) {
    font-size: rem-calc(42);
  }
  @media($mq-desktop) {
    font-size: rem-calc(50);
  }
}

.ContributorsList-letterList {
  margin-bottom: 25px;

  @media($mq-from-tablet) {
    margin-bottom: 40px;
  }
  @media('min-width: 700px') and ('max-width: 900px') {
    column-count: 2;
  }
  @media('min-width: 900px') and ('max-width: 1200px') {
    column-count: 3;
  }
  @media('min-width: 1200px') {
    column-count: 4;
  }

  li a {
    display: inline-block;
    margin-bottom: 2px;
    @include text-underline($text-decoration-color: $color-darkPrimary);

    &:hover, #{$keyboard} &:focus {
      text-decoration-color: $color-darkPrimary;
    }
  }
}