// =========
// Book item
// =========

.BookItem {
  position: relative;
  margin-top: 28px;
  margin-bottom: 10px;

  @media($mq-from-tablet) {
    margin-top: 35px;
  }

  @media print {
    width: 70%;
    margin-top: -5px;
  }

}

.BookItem-container:hover, #{$keyboard} .BookItem-container:focus {
  .BookItem-cover {
    @media($mq-from-tablet) {
      background-color: $color-darkPrimary;
    }
  }
}


// -------
// Content
// -------

.BookItem-status {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  top: -35px;
  left: 0;

  .WishList & {
    flex-direction: row;
  }
}

.BookItem-cover {
  position: relative;
  background-color: transparent;
  transition: background-color .25s ease-in-out;

  img { // height: 294px;
    margin: 0 auto;
    object-fit: contain;
    mix-blend-mode: exclusion;
  }
}

.BookItem-text {
  padding: 12px 0;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }

  .Button {
    z-index: 2;
    position: relative;
    transform: scale(.75);
  }
}

.BookItem-subtext {
  color: $color-darkPrimary;
  font-size: rem-calc(12);

  & > *:not(:last-child)::after {
    content: ' - ';
  }
}

.BookItem-contributor {
  line-height: 1.1;

  a {
    z-index: 2;
    position: relative;
    padding: 5px 0;
    color: $color-darkGreige;
    font-size: rem-calc(12);
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      text-decoration-color: inherit;
    }
  }
}

.BookItem-title {
  @include font-bold($font-family: $font-family-title);
  color: $color-darkPrimary;
  font-size: rem-calc(22);
  line-height: 1.2;

  @media($mq-from-tablet) {
    font-size: rem-calc(24);
  }

  a {
    @include hover-all;
    transition: color .25s ease-in-out;
  }
}

.BookItem-price {
  @include font-medium;
  font-size: rem-calc(14);
}

// HEART BUTTON OVERRIDE
.BookItem {
  .Button--secondary.Button--green[data-wishlist-item] {
    position: relative;
    padding: 0;
    background-color: transparent;
    color: $color-secondary;

    .icon {
      margin-right: 0;
      width: 20px;
      height: 20px;
    }

    .icon-heart {
      display: none;
    }
    &.wishlisted {
      .icon-heart-empty {
        display: none;
      }
      .icon-heart {
        display: block;
        color: $color-secondary;
      }
    }
  }
}

.BookItem-ean{
  display : none;
}


