// =============
// Section style
// =============

.Section {
  background-color: $color-white;

  &[class*="Footer"] {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      top: calc(20% + 115px);
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);

      @media($mq-from-tablet) {
        top: 314px;
      }
    }

    & > * {
      z-index: 1;
    }
  }

  // Color variant
  &--lightblue {
    background-color: $color-lightPrimary;
  }
  &--lightgreige {
    background-color: $color-lightGreige;
  }

  // Footer color variant
  &--lightblueFooter::before {
    background-color: $color-lightPrimary;
  }
  &--lightgreigeFooter::before {
    background-color: $color-lightGreige;
  }
  &--whiteFooter::before {
    background-color: $color-white;
  }

  .Wrapper {
    padding-top: 30px;
    padding-bottom: 30px;

    @media($mq-from-tablet) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }
}


// ------------------
// Keyboard detection
// ------------------

#{$keyboard} .Section {

  // Color red
  &--red {
    .NewsItem:focus::after {
      border-color: $color-red;
    }

    .BookItem-title a:focus, .TipsItem-title a:focus,
    .CirclesList-item a:focus, .NewsItem:focus {
      color: $color-red;
    }
  }

  // Color green
  &--green {
    .NewsItem:focus::after {
      border-color: $color-green;
    }

    .BookItem-title a:focus, .TipsItem-title a:focus,
    .CirclesList-item a:focus, .NewsItem:focus {
      color: $color-green;
    }
  }

  // Color yellow
  &--yellow {
    .NewsItem:focus::after {
      border-color: $color-yellow;
    }

    .BookItem-title a:focus, .TipsItem-title a:focus,
    .CirclesList-item a:focus, .NewsItem:focus {
      color: $color-yellow;
    }
  }

  // Color blue
  &--blue {
    .NewsItem:focus::after {
      border-color: $color-blue;
    }

    .BookItem-title a:focus, .TipsItem-title a:focus,
    .CirclesList-item a:focus, .NewsItem:focus {
      color: $color-blue;
    }
  }
}