// ==============
// Book component
// ==============

.Book-main {
  position: relative;
  padding-top: 20px;

  @media($mq-from-tablet) {
    padding-top: 63px;
  }

  & > .Wrapper {
    display: flex;

    @media($mq-phone) {
      padding-top: 0;
    }
    @media($mq-upto-tablet) {
      flex-direction: column;
      align-items: center;
    }
  }
}

// --------------
// Book left side
// --------------

.Book-leftSide {
  position: relative;
  max-width: 370px;
  width: 100%;

  @media($mq-phone) {
    padding: 15px 5px 0 0;
  }
  @media($mq-tablet) {
    padding-bottom: 25px;
  }
  @media($mq-desktop) {
    margin-right: 90px;
  }
}

.Book-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: 0;
  top: -35px;
  color: $color-darkGray;
  font-size: rem-calc(13);

  @media($mq-phone) {
    margin-top: -10px;
    padding: 0 0 15px;
  }
  @media($mq-from-tablet) {
    position: absolute;
  }
}

.Book-cover {
  z-index: 2;
  position: relative;
  margin-bottom: 10px;

  img {
    width: 100%;
  }
}

.Book-options {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 20px 0;
  }
}

.Book-leafButton {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0;

  @media($mq-phone) {
    margin-bottom: 30px;
  }

  .Button {
    max-width: 370px !important;
  }
}

.Book-download {
  display: block;
  margin-top: 10px;
  margin-bottom: 25px;
  text-align: center;

  .Book-leafButton ~ & {
    margin-top: 0;
  }
}

.Book-share {
  margin: 0;

  @media($mq-upto-tablet) {
    display: none;
  }

  .SocialNetworks {
    justify-content: center;
  }
}

// ---------------
// Book right side
// Primary info
// ---------------

.Book-rightSide {
  width: 100%;
}

.Book-primaryInfo {
  margin-bottom: 30px;
}

// Header
.Book-selection {
  display: flex;
  justify-content: flex-end;
  margin-top: -36px;
  margin-bottom: 15px;

  @media ($mq-phone) {
    margin-top: 0;
    justify-content: center;
  }

  .Button .icon {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.Book-contributors {
  color: $color-darkGreige;
  @include font-medium;
  font-size: rem-calc(18);

  @media($mq-phone) {
    flex: 1 0 auto;
    padding-top: 10px;
    padding-right: 15px;
  }

  p + p {
    margin-top: 1px;
  }

  a {
    text-decoration: underline;
    transition: color .25s ease-in-out,
    text-decoration-color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      color: $color-darkPrimary;
      text-decoration-color: inherit;
    }
  }
}

// Main
.Book-infoMain {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 12px;
  color: $color-darkPrimary;
  align-items: flex-start;

  & > div {
    flex: 1;
    padding-right: 35px;
    font-size: rem-calc(18);

    & > * {
      margin-bottom: 6px;
    }
  }

  @media($mq-phone) {
    .Button {
      display: none;
    }
  }
}

.Book-title {
  margin-bottom: 10px !important;
  @include font-title;
  font-size: rem-calc(42);
  line-height: 1;

  @media($mq-from-tablet) {
    font-size: rem-calc(62);
  }
}

.Book-subtitle {
  @include font-bold($font-family: $font-family-title);
  font-size: rem-calc(22);
  line-height: 1.2;
}

.Book-tomeTitle {
  color: $color-darkPrimary;
}

.Book-comments {
  color: $color-primary;
  font-size: rem-calc(13.3);
  line-height: 1.5;
}

// Resume
.Book-infoResume {
  padding-top: 12px;
  padding-bottom: 35px;
  color: $color-darkPrimary;

  &.RichText strong p {
    margin-bottom: 10px;
  }
}

// Footer
.Book-categories {
  @include font-medium;
  color: $color-secondary;

  p {
    margin-bottom: 15px;
    font-size: rem-calc(18);
  }
}

// ---------------
// Book right side
// Secondary info
// ---------------

// Required for Foundation's Accordion init
.Book-secondaryInfo .accordion-content {
  display: none;
}

// Book buy
.Book-buy {
  & > p {
    padding-top: 5px;
    padding-bottom: 8px;
    border-bottom: 2px solid $color-gray;
    color: $color-secondary;
    @include font-medium;
    font-size: rem-calc(18);
  }

  & > ul {
    display: flex;
    margin: 0 -17px;
    padding: 15px 47px 32px 0;

    @media($mq-phone) {
      flex-direction: column;
    }

    & > li {
      padding: 0 17px;

      @media($mq-phone) {
        max-width: 400px;
        width: 100%;

        & + li {
          margin-top: 25px;
        }
      }
      @media($mq-from-tablet) {
        width: 50%;
        max-width: 400px;
      }

      a {
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color .25s ease-in-out;

        &:hover, #{$keyboard} &:focus {
          text-decoration-color: $color-darkGray;
        }
      }
    }
  }
}

.Book-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: $color-darkPrimary;
  font-size: rem-calc(18);
}

// Book awards
.Book-awards {
  .accordion-content > ul {
    padding: 15px 47px 32px 0;
    color: $color-darkPrimary;

    li {
      position: relative;
      padding-bottom: 5px;
      font-size: rem-calc(18);
      line-height: 1.2;
    }
  }
}

// Book details
.Book-details {
  .accordion-content > ul {
    display: flex;
    padding: 15px 47px 0 0;
    font-size: rem-calc(18);

    @media($mq-phone) {
      flex-direction: column;
    }
    @media($mq-from-tablet) {
      & > li {
        flex: 1;

        &:first-child {
          padding-right: 20px;
        }
        &:last-child {
          padding-left: 20px;
        }
      }
    }

    p {
      color: $color-darkPrimary;

      strong {
        color: $color-darkPrimary;
        @include font-medium;
      }
    }

    a strong {
      text-decoration: underline;
      text-decoration-color: $color-darkGray;
      transition: color .25s ease-in-out,
      text-decoration-color .25s ease-in-out;
    }

    a:hover strong, a:focus strong {
      color: $color-darkGreige;
      text-decoration-color: inherit;
    }
  }
}

.Book-detailsSet {
  margin-bottom: 10px;

  &--main {
    span:not(:last-child)::after {
      content: '-';
      padding-left: 5px;
      padding-right: 1px;
    }
  }
  &:not(.Book-detailsSet--main) p {
    line-height: 1.35;
  }
}

// Phone variant
.Book-phoneInfo {
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media($mq-upto-tablet) {
    .Book-share {
      display: block;
    }
  }
  @media($mq-desktop) {
    display: none;
  }
}

// Detailed Description
.Book-detailedDescription {
  border-top: 2px solid $color-gray;
  color: $color-darkPrimary;

  strong {
    @include font-medium;
  }
}

// ----
// Leaf
// ----

.Book-leaf .Wrapper {
  z-index: 1;
  position: relative;
  width: 100%;
}

.Book-leafContainer {
  margin-top: 50px;
  margin-bottom: 10px;
  border: 1px solid $color-primary;
}

.Book-leafBox {
  max-width: calc(1200px - 2px);
  width: 100%;
  height: 650px;
  background-color: $color-white;

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

// -----
// Press
// -----

.Book-press {
  overflow: hidden;

  .Wrapper {
    position: relative;

    .icon-quote {
      position: absolute;
      width: 210px;
      height: 185px;
      top: 70px;
      left: 20px;
      color: $color-white;
    }
  }
}

.Book-pressContainer {
  position: relative;
  margin-top: 35px;
  margin-bottom: 10px;
  padding: 25px 20px;
  background-color: $color-lightGreige;
  border-left: 3px solid $color-secondary;
  color: $color-secondary;
  transition: height .5s ease-out;

  @media($mq-from-tablet) {
    margin-top: 50px;
    padding: 30px 20px;
  }
}


.Book-resumeButton:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 99%;
}

.Book-resumeButton {
  margin-top: 20px;
  position: relative;

  &:not(.Truncate-unexpandButton)::before {
    background: linear-gradient(to bottom, rgba($color-lightGreige, 0), $color-lightGreige);
  }

  &:hover, &:focus {
    outline: 0;
  }

  button {
    &:hover, &:focus {
      outline: 0;
    }
  }
}

// ------
// Videos
// ------

.Book-videos .RichText {
  margin-top: 45px;

  h3 {
    margin-bottom: 5px;
    @include font-title;
    font-size: rem-calc(40);
  }
  p {
    @include font-medium;
    font-size: rem-calc(14);
  }
}

// ----------------
// Siblings variant
// ----------------

.Book-main + .Book-series {
  background: $color-white;

  & + .Book-suggestion {
    background-color: $color-lightGreige;
  }
}

.Book-press.Section .Wrapper {
  border-top: 3px solid $color-secondary;
}
