// =============
// Accessibility
// =============


// suppress focus ring on form controls for mouse users
[data-whatintent='mouse'] *:focus {
  outline: none;
}

.desktopOnly {
  @media ($mq-phone) {
    display: none !important;
  }
}



.mobileAndTabletOnly {
  @media ($mq-from-tablet) {
    display: none !important;
  }
}

.mobileOnly {
  @media ($mq-desktop) {
    display: none !important;
  }
}
