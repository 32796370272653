// ===============
// Select Dropdown
// ===============

.SelectDropdown {
  position: relative;
}

.SelectDropdown-button {
  &.Button {
    max-width: none;
  }
  &.alignLeft {
    text-align: left;
  }
}

.SelectDropdown-pane > * {
  display: none;
  z-index: 10;
  position: absolute;
  width: 100%;
  padding: 7px 0;
  top: -2px;
  background-color: $color-white;
  border: 2px solid $color-gray;
  border-top-color: $color-primary;
  font-size: rem-calc(14);
  overflow: auto;
  transition: border-color .25s ease-in-out;

  [aria-expanded="true"] ~ & {
    border-color: $color-secondary;
  }
}


// ----------
// List style
// ----------

.SelectDropdown-paneList {
  max-height: 180px;

  & > * {
    max-height: 205px;
  }

  li a {
    display: block;
    padding: 6px 20px 5px;
  }
}


// ----------
// Grid style
// ----------

.SelectDropdown-paneGrid ul {
  display: flex;
  flex-wrap: wrap;
  padding-top: 3px;
  padding-left: 3px;

  li {
    width: calc(100% / 3);
    padding: 5px 0;

    &.large {
      width: 100%;
    }

    a {
      padding: 6px 15px 5px;

      &:hover, &:focus, &.active {
        @include font-bold;
      }
    }
  }
}
