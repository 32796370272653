// ========================
// Search Results component
// ========================

.SearchResults-header {
  position: relative;
  padding-top: 75px;

  @media($mq-from-tablet){
    padding-top: 95px;
  }

  .Wrapper {
    display: flex;
    justify-content: space-between;
  }
}


// -----------
// Header Left
// -----------

.SearchResults-title {
  flex: 1;
  margin: -10px 0 10px;
  @include font-bold($font-family: $font-family-title);
  font-size: rem-calc(30);
  line-height: 1.1;

  @media($mq-from-tablet) {
    margin: -10px 0 15px;
    font-size: rem-calc(45);
  }
}

.SearchResults-categories {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .Button--active {
    order: -1;
  }
}

.SearchResults-category {
  margin-top: 10px;
  margin-right: 10px;
}


// ------------
// Header Right
// ------------

.SearchResults-headerRight {

  max-width: 243px;
  width: 100%;
  margin-top: -69px;
  margin-left: 30px;
  padding: 69px 0 30px 30px;
  background-color: $color-lightenGray;

  @media($mq-upto-tablet) {
    display: none;
  }

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    width: calc(50vw - 365px);
    height: 100%;
    top: 0;
    right: 0;
    background-color: $color-lightenGray;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
}

.SearchResults-options {
  li:not(:last-child) {
    margin-bottom: 10px;
  }

  .Button {
    display: block;
    position: relative;
    text-align: left;

    .icon {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 5px;
      right: -5px;
    }
  }

  // Download button
  li:last-child .Button .icon {
    transform: rotate(90deg);
  }
}


// ----
// List
// ----

.SearchResults-list {
  position: relative;
  padding-top: 49px;

  @media($mq-from-tablet){
    padding-top: 69px;
  }

  .Wrapper {
    display: flex;

    @media($mq-upto-tablet) {
      flex-direction: column;
    }
  }
}

.SearchResults-main {
  width: 100%;
  padding-top: 25px;
  // Avoid anchoring on clicking read more button
  overflow-anchor: none;

  @media($mq-tablet) {
    padding: 15px 0 40px;
  }
  @media($mq-desktop) {
    padding: 0 0 40px 45px;
  }

  &Tags {
    @media($mq-desktop) {
      padding: 65px 0 40px 45px;
    }
  }
}


// -------
// Taglist
// -------

.SearchResults-tags {
  position: relative;
  margin-bottom: 15px;

  @media($mq-desktop) {
    margin-bottom: 10px;
  }
}

.SearchResults-tagsContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-size: rem-calc(14);

  @media($mq-phone) {
    flex-direction: column;
  }
  @media($mq-desktop) {
    position: absolute;
    align-items: flex-end;
    top: -32px;
    left: 0;
  }
}

.SearchResults-taglist {
  flex: 1;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;

    @media($mq-desktop) {
      position: absolute;
      width: calc(100% - 160px);
      margin-bottom: -4px;
      bottom: 0;
    }
  }
}

.SearchResults-tag {
  position: relative;
  padding: 6px 28px 5px 10px;
  margin: 5px;
  text-align: left;

  .icon {
    position: absolute;
    width: 10px;
    height: 10px;
    margin: 0;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.SearchResults-resetTags {
  padding: 10px 5px 5px;
  color: $color-primary;
  @include font-medium;
  text-decoration: underline;
  transition: color .25s ease-in-out;

  @media($mq-phone) {
    margin-left: -5px;
  }
  @media($mq-from-tablet) {
    margin-right: -5px;
  }
  @media($mq-desktop) {
    padding: 2px 5px 0;
  }

  &:hover, #{$keyboard} &:focus {
    color: $color-darkPrimary;
  }
}


// ---------
// Show More
// TODO: Use ShowMore component instead
// ---------

.SearchResults-show {
  display: flex;
  justify-content: center;
  width: 100% !important;

  & ~ * { // Hide all next siblings
    display: none !important;
  }
}

.SearchResults-showContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > p {
    margin-bottom: 10px;
  }
}

.SearchResults-showProgress {
  position: relative;
  width: 200px;
  height: 10px;
  margin-bottom: 25px;

  span:first-child {
    position: absolute;
    width: 200px;
    height: 10px;
    background-color: rgba($color-darkPrimary, .25);
    border: 1px solid rgba($color-darkPrimary, .75);
    border-radius: 10px;
    top: 0;
    left: 0;
  }

  span:last-child {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--progress);
    height: 10px;
    background-color: rgba($color-darkPrimary, 1);
    border-radius: 10px;
  }
}


// ------
// Footer
// ------

.SearchResults-footer {

  // ToDo: remove when available
  display: none;
  
  padding-top: 45px;
  padding-bottom: 35px;

  @media($mq-desktop) {
    display: none;
  }
  @media($mq-tablet) {
    margin-top: -45px;
  }

  .SearchResults-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -10px;

    @media($mq-phone) {
      flex-direction: column;
      align-items: center;
    }

    li {
      max-width: 200px;
      width: 100%;
      margin-bottom: 10px;

      @media($mq-tablet) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
