// ===========
// Klaro style
// ===========

.klaro-hidden {
  display: none !important;
}

.RichText .consent-fallback {
  padding: 30px 10px 20px;
  background-color: $color-primary;
  color: $color-white;

  p:not([class]) {
    font-family: "Poppins", Arial, sans-serif;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  button {
    background-color: #fff;
    border: 1px solid #fff;
    color: $color-primary;
  }
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app p.purposes {
  display: none;
}

.klaro{
  .cm-footer-buttons{
    .cm-btn-accept{
      order: 1;
    }
    .cm-btn-decline{
      order : 2;
    }
    .cm-btn-accept-all{
      order : 3;
    }
  }
}
