// ======
// Slider
// ======

.Slider {
  position: relative;
  display: inline-block;
  width: 100%;
}

.Slider-container {
  @media('max-width: 1399px') {
    overflow: visible;
  }
}

.Slider-slide {
  @include xy-cell(shrink, $gutters: 0);
}


// ------------------
// Navigation buttons
// ------------------

.Slider-button {
  position: absolute;
  padding: 20px;
  top: 62px;

  &:not([aria-disabled="true"]):hover,
  #{$keyboard} &:not([aria-disabled="true"]):focus {
    .icon {
      color: $color-darkPrimary;
    }
  }

  @media('max-width: 1399px') {
    display: none;
  }

  .icon {
    width: 27px;
    height: 54px;
    color: $color-primary;
    transition: color .25s ease-in-out;
  }

  &Prev {
    left: -93px;

    .icon {
      transform: rotate(180deg);
    }
  }

  &Next {
    right: -93px;
  }
}


// --------------
// Slider bullets
// --------------

.Slider-bullets {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: auto !important;
  margin: 0 auto;

  @media($mq-phone) {
    margin-top: 20px;
  }
  @media('min-width: 1400px') {
    display: none;
  }
}

.Slider-bullet {
  width: 26px;
  height: 26px;
  margin: 0 2px;
  padding: 8px;
  background-color: transparent;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: $color-lightGray;
    border-radius: 50%;
  }
}