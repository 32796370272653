// =============
// Homepage News
// =============

.HomepageNews-list {
  @media($mq-phone) {
    margin-bottom: 20px;
  }

  .swiper-container {
    @media($mq-upto-tablet) {
      margin-top: 40px;
      overflow: visible;
    }
  }

  ul {
    z-index: 0;

    @media($mq-from-tablet) {
      margin-top: 10px;
    }
  }
}

.HomepageNews-listItem {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  margin: 5px 0 10px;

  @media($mq-from-tablet) {
    width: calc(100% / 3 - 30px);
    margin: 15px 0;
  }

  .NewsItem {
    width: 100%;
  }

  .NewsItem-container {
    height: 100%;
  }
}