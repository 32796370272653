// =========
// Show More
// =========

.ShowMore {
  display: flex;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 45px !important;

  // BooksList variant
  .BooksList &::after {
    height: 175px !important;
    background-color: $color-white !important;
  }

  // Hide all next siblings
  & ~ * {
    display: none !important;
  }
}

.ShowMore-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > p {
    margin-bottom: 10px;
  }
}

.ShowMore-progress {
  position: relative;
  width: 200px;
  height: 10px;
  margin-bottom: 25px;
}

.ShowMore-fullBar {
  position: absolute;
  width: 200px;
  height: 10px;
  background-color: $color-lightGray;
  border: 1px solid $color-gray;
  border-radius: 10px;
  top: 0;
  left: 0;
}

.ShowMore-progressBar {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--progress);
  height: 10px;
  background-color: $color-darkGray;
  border-radius: 10px;
}
