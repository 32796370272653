// ========
// Category
// ========

// Museum specificity
.Category-museum {
  @media($mq-desktop) {
    padding-top: 15px;
  }

  &::before {
    top: 260px !important;

    @media($mq-from-tablet) {
      top: 275px !important;
    }
  }
}

.Category-museumList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px -15px 0;
}

.Category-museumItem {
  position: relative;
  margin: 15px;

  &:hover, #{$keyboard} &:focus {
    .Category-museumImage {
      background-color: $color-darkPrimary;
    }
  }

  a {
    @include hover-all;
    display: block;
    margin-top: 15px;
    @include font-title;
    font-size: rem-calc(22);

    @media($mq-from-tablet) {
      font-size: rem-calc(36);
    }
  }
}

.Category-museumImage {
  position: relative;
  transition: background-color .25s ease-in-out;

  img {
    object-fit: contain;
    mix-blend-mode: exclusion;
  }
}