// =================
// Homepage Networks
// =================

.HomepageNetworks {
  .Wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}


// ----------
// Navigation
// ----------

.HomepageNetworks-navigation {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: -20px;

  @media($mq-phone) {
    margin-right: -12px;
  }
}

.HomepageNetworks-button {
  margin: 10px 20px;

  @media($mq-phone) {
    margin: 0 12px;
    transform: scale(.75);
  }

  &#{Prev} .icon {
    transform: rotate(180deg);
  }
  &[aria-disabled="false"] {
    &:hover .icon,
    #{$keyboard} &:focus .icon {
      color: $color-dark;
    }
  }
  &[aria-disabled="true"] {
    cursor: default;
  }

  .icon {
    width: 19px;
    height: 37px;
    color: $color-primary;
    transition: color 0.25s ease-in-out;
  }
}

.HomepageNetworks-list {
  .Slider-container {
    overflow: visible;
  }

  .Slider-wrapper {
    z-index: 0;
    margin: 27px 0 35px;

    @media($mq-phone) {
      margin: 7px 0 20px;
    }
  }
}


// --------
// Networks
// --------

.HomepageNetworks-listItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 75%;
  height: 462px;
  &.HomepageNetworks-listItem--twitter iframe {
    position: absolute !important;
    width:100%!important;
  }
  @media('min-width: 600px') and ('max-width: 1099px') {
    padding-top: 53%;
  }
  @media($mq-tablet) {
    padding-top: 42%;
  }
  @media($mq-desktop) {
    padding-top: 38%;
  }

  iframe {
    max-height: 462px;
    min-width: 0 !important;
    top: 0;
  }

  // Networks variants
  &--facebook .fb-page {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-size: cover;
  }
  &--twitter img {
    position: absolute;
    height: 100%;
    top: 0;
  }
}

.HomepageNetworks-iframe {
  position: absolute;
  width: 100%;
  min-width: 0;
  max-height: 462px;
  height: 100%;
  top: 0;
  background-color: $color-lightPrimary;
  background-size: cover;

  &.Instagram {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
  }

  &:not(.Instagram) img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &.Instagram a:not(.noLoaded) {
    width: 100%;
    height: 100%;
    display: flex;
    img {
      max-height: 462px;
      margin: auto;
    }
  }
  &.Instagram a.noLoaded {
    background-color: $color-darkPrimary;
  }
}

.HomepageNetworks-link {
  position: absolute;
  width: 100%;
  padding-top: 20px;
  top: 100%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
