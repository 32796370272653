// ==============
// Truncate style
// ==============

.Truncate {
  overflow: hidden;
  transition: height .25s ease-out;

  &--bookResume {
    margin-bottom: -15px;
  }
}

.Truncate-button {
  position: relative;
  margin-top: 20px;
  padding-top: 5px;
}

.Truncate-expandButton {
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 100%;
    background: linear-gradient(to bottom, rgba($color-white, 0), $color-white);
  }

  .Section--lightgreige &::before {
    background: linear-gradient(to bottom, rgba($color-lightGreige, 0), $color-lightGreige);
  }

  .Hero-container &::before, .Hero-content &::before {
    background: linear-gradient(to bottom, rgba($color-white, 0), $color-white);
  }

  .Truncate[aria-expanded="true"] ~ & {
    display: none;
  }
}

.Truncate-unexpandButton {
  .Truncate[aria-expanded="false"] ~ & {
    display: none;
  }
}