// ======
// Mixins
// ======

// Switch to grayscale font smoothing on OSX
@mixin osx-font-smoothing-grayscale {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Font style
@mixin font-title($font-family: $font-family-title, $text-transform: initial) {
  font-family: $font-family;
  font-weight: 400;
  text-transform: $text-transform;
}
@mixin font-base($font-family: $font-family-base, $text-transform: initial) {
  font-family: $font-family;
  font-weight: 400;
  text-transform: $text-transform;
}

// Font weight
@mixin font-light($font-family: $font-family-base, $text-transform: initial) {
  font-family: $font-family;
  font-weight: 300;
  text-transform: $text-transform;
}
@mixin font-regular($font-family: $font-family-base, $text-transform: initial) {
  font-family: $font-family;
  font-weight: 400;
  text-transform: $text-transform;
}
@mixin font-italic($font-family: $font-family-base, $text-transform: initial) {
  font-family: $font-family;
  font-style: italic;
  font-weight: 400;
  text-transform: $text-transform;
}
@mixin font-medium($font-family: $font-family-base, $text-transform: initial) {
  font-family: $font-family;
  font-weight: 500;
  text-transform: $text-transform;
}
@mixin font-semibold($font-family: $font-family-base, $text-transform: initial) {
  font-family: $font-family;
  font-weight: 600;
  text-transform: $text-transform;
}
@mixin font-semibold-italic($font-family: $font-family-base, $text-transform: initial) {
  font-family: $font-family;
  font-style: italic;
  font-weight: 600;
  text-transform: $text-transform;
}
@mixin font-bold($font-family: $font-family-base, $text-transform: initial) {
  font-family: $font-family;
  font-weight: 700;
  text-transform: $text-transform;
}
@mixin font-extrabold($font-family: $font-family-base, $text-transform: initial) {
  font-family: $font-family;
  font-weight: 800;
  text-transform: $text-transform;
}

// Full hover effect
@mixin hover-all($position: after) {
  &::#{$position} {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

// Text underline effect
@mixin text-underline($color: inherit, $text-decoration-color: inherit) {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: color .25s ease-in-out,
              text-decoration-color .25s ease-in-out;

  &:hover, #{$keyboard} &:focus {
    color: $color;
    text-decoration-color: $text-decoration-color;
  }
}