// ===============
// Modal component
// ===============

// Modal overlay
.reveal-overlay {
  z-index: 100000;
}

.Modal {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  max-width: 920px;
  width: 100%;
  max-height: 100vh;
  min-height: 0;
  height: 100%;
  padding: 20px;
  top: 0 !important;
  background-color: transparent;
  border: none;
  filter: blur(.0px); // Chrome bugfix

  .Title {
    padding-top: 10px;
    padding-right: 40px;

    @media($mq-from-tablet) {
      padding-right: 50px;
    }
  }
}

.Modal-container {
  height: auto;
  //height: 100%;
  padding: 20px;
  background-color: $color-white;
  overflow: hidden;

  @media($mq-from-tablet) {
    padding: 20px 30px;
  }
}

.Modal-closeButton {
  width: 45px;
  height: 45px;
  padding: 10px;
  top: 30px;
  right: 30px;
  color: $color-darkGray;
  transition: color .25s ease-in-out;

  @media($mq-from-tablet) {
    width: 50px;
    height: 50px;
    top: 35px;
    right: 40px;
  }

  &:hover, #{$keyboard} &:focus {
    color: $color-dark;
  }

  .icon {
    width: 25px;
    height: 25px;

    @media($mq-from-tablet) {
      width: 30px;
      height: 30px;
    }
  }
}


// --------------
// Buy Book modal
// --------------

.Modal {
  height: auto;
  top: 50% !important;
  outline: 0;
  transform: translateY(-50%);

  .TipsItem-text {
    margin-bottom: 30px;
  }

  .TipsItem-containerView {
    max-height: calc(100vh - 174px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .Book-buy > ul {
    width: calc(100% + 17px);
    padding-bottom: 0;
    overflow: hidden;
  }

  @media($mq-phone) {
    .Book-buy > ul {
      padding-right: 0;
    }
    .SelectDropdown-button[aria-expanded="true"] ~ .SelectDropdown-paneList ul {
      display: block;
    }
    .SelectDropdown-paneList {
      max-height: none;

      ul {
        position: relative;
      }
    }
  }
  @media($mq-from-tablet) {
    width: auto;
    height: auto;
    margin: 0;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);

    .SelectDropdown {
      height: 100%;
      margin-bottom: 35px;
    }
    .SelectDropdown-pane ul {
      position: relative;
      display: block !important;
    }
    .SelectDropdown-button {
      border-color: $color-darkGray;
      pointer-events: none;
    }
    .Book-buy > ul > li {
      width: 100%;
    }
  }
}

// Message Modal - wishList

