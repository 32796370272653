// ===============
// Title component
// ===============

.Title {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $color-darkPrimary;
  @include font-regular($font-family: $font-family-title);
  line-height: 1.2;

  & > span {
    position: relative;
    display: inline-block;
    margin-bottom: -4px;
    padding-bottom: 2px;
  }

  // Primary
  &--primary {
    position: relative;
    margin-bottom: 20px;
    font-size: rem-calc(30);

    @media($mq-from-tablet) {
      font-size: rem-calc(45);
    }

    .Button::after {
      bottom: 4px;
    }
  }

  // Secondary
  &--secondary {
    display: flex;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 4px;
    font-size: rem-calc(22);

    span::after {
      bottom: -1px;
      border-bottom: 2px solid;
    }
  }
}

// ---------------
// Accordion title
// ---------------

.Title.accordion-title {
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 0;
  border-top: 3px solid $color-secondary;
  border-bottom: 0;
  color: $color-secondary;
  @include font-medium($font-family: $font-family-base);
  font-size: rem-calc(18);

  .icon {
    width: 17px;
    height: 17px;
    margin-left: 20px;
    margin-right: 10px;
    transform: rotate(-90deg);
  }
}
a.Title {
  cursor: pointer;
}


// -------------------
// Homepage link title
// -------------------

.Homepage .Section .Title--primary:not(:first-child) {
  @media($mq-phone) {
    margin-bottom: 0;
  }
  @media($mq-from-tablet) {
    display: none;
  }
}
.Homepage .Section .Title--primary:not(:last-child) a {
  @media($mq-phone) {
    display: none;
  }
}
