// ==============
// News component
// ==============

.News-main {
  position: relative;
  padding-top: 40px;
}


// ------
// Header
// ------

.News-headerTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.News-type {
  font-size: rem-calc(18);
  text-transform: uppercase;
}

.News-date {
  color: $color-primary;
  font-size: rem-calc(13.3);
}

.News-headerMain {
  @media($mq-desktop) {
    padding-right: 165px;
  }
}

.News-heading {
  margin-bottom: 35px;
}

//.News-title {
//  color: $color-primary;
//  @include font-bold($font-family: $font-family-title);
//  font-size: rem-calc(35);
//  line-height: 1.1;
//
//  @media($mq-tablet) {
//    font-size: rem-calc(62);
//  }
//  @media($mq-desktop) {
//    font-size: rem-calc(70);
//  }
//}

.News-subtitle {
  color: $color-darkGreige;
  @include font-light;
  font-size: rem-calc(18);
  line-height: 1.1;

  @media($mq-tablet) {
    font-size: rem-calc(20);
  }
  @media($mq-desktop) {
    font-size: rem-calc(22);
  }

  .News-title + & {
    margin-top: 10px;
  }
}

.News-dates {
  margin-top: 20px;
  color: $color-darkGreige;
  font-size: rem-calc(20);
  line-height: 1.1;

  @media($mq-from-tablet) {
    font-size: rem-calc(35);
  }
}

.News-intro {
  font-size: rem-calc(16);
  @include font-medium;

  @media($mq-tablet) {
    font-size: rem-calc(17);
  }
  @media($mq-desktop) {
    font-size: rem-calc(18);
  }
}

.News-share {
  margin-top: 25px;

  @media($mq-from-tablet) {
    margin-top: 40px;
  }
}


// -------
// Content
// -------

.News-content {
  margin-top: 25px;

  @media($mq-from-tablet) {
    margin-top: 40px;
  }

  & > *:not(:last-child) {
    margin-bottom: 60px;
  }
}
