// ===========
// FUTUROPOLIS
// ===========

// Futuropolis theme
$theme: "futuropolis";

// Fonts
$font-sans-name: 'Poppins';
$font-serif-name: 'Playfair Display';

// Unused : light, light-italic, medium-italic, semi-bold, semi-bold-italic, bold-italic
$font-sans-map: (
  'poppins/poppins-regular': 400 normal,
  'poppins/poppins-italic': 400 italic,
  'poppins/poppins-medium': 500 normal,
  'poppins/poppins-bold': 700 normal
);

$font-serif-map: (
  'playfair-display/playfair-display-regular': 400 normal,
  'playfair-display/playfair-display-italic': 400 italic,
  'playfair-display/playfair-display-bold': 700 normal
);

// Main styles
@import "main";

body {
  color: $color-darkPrimary;
}

// Colors
$color-dark:            #38464E;
$color-darkGreige:      #664A35;
$color-greige:          #B49680;
$color-lightGreige:     #F7F4F2;
$color-green-modal:     #38D7BE;
// Color Primary: petroleum
$color-darkPrimary:     #34464F;
$color-primary:         #748A96;
$color-lightPrimary:    rgba($color-primary, .2);

// Color Secondary: night green
$color-secondary:       #07545C;

$color-brown:           #664A35;



// ======== START TO UPDATE ================

@mixin gray-footer($margin-top, $bottom: 0) {
  &:last-child::before {
    content: '';
    z-index: -1;
    position: absolute;
    width: 300vw;
    margin-top: $margin-top;
    background-color: $color-lightenGray;
    top: 0;
    bottom: $bottom;
    left: -100vw;
  }
}

// Jeunesse components
@import "../components/jeunesse/collection/collection";
@import "../components/jeunesse/contributor/contributor";
@import "../components/jeunesse/modal/modal";
@import "../components/jeunesse/show-more/show-more";
@import "../components/jeunesse/videos/videos";
@import "../components/jeunesse/websites-list/websites-list";

// Futuropolis components
@import "../components/futuropolis/articles-list/articles-list";
@import "../components/futuropolis/book/book";
@import "../components/futuropolis/book-item/book-item";
@import "../components/futuropolis/books-filters/books-filters";
@import "../components/futuropolis/books-list/books-list";
@import "../components/futuropolis/books-slider/books-slider";
@import "../components/futuropolis/breadcrumbs/breadcrumbs";
@import "../components/futuropolis/button/button";
@import "../components/futuropolis/category/category";
@import "../components/futuropolis/circles-list/circles-list";
@import "../components/futuropolis/contributor/contributor";
@import "../components/futuropolis/contributors-list/contributors-list";
@import "../components/futuropolis/cookie-banner/cookie-banner";
@import "../components/futuropolis/filters/filters";
@import "../components/futuropolis/footer/footer";
@import "../components/futuropolis/header/header";
@import "../components/futuropolis/header/header-search";
@import "../components/futuropolis/hero/hero";
@import "../components/futuropolis/homepage/homepage";
@import "../components/futuropolis/homepage/homepage-networks/homepage-networks";
@import "../components/futuropolis/homepage/homepage-news/homepage-news";
@import "../components/futuropolis/homepage/homepage-novelty/homepage-novelty";
@import "../components/futuropolis/homepage/homepage-prepublications/homepage-prepublications";
@import "../components/futuropolis/homepage/homepage-slider/homepage-slider";
@import "../components/futuropolis/menu/menu";
@import "../components/futuropolis/news/news";
@import "../components/futuropolis/news-embed/news-embed";
@import "../components/futuropolis/news-item/news-item";
@import "../components/futuropolis/news-slider/news-slider";
@import "../components/futuropolis/newsletter/newsletter";
@import "../components/futuropolis/page/page";
@import "../components/futuropolis/proarea/proarea";
@import "../components/futuropolis/richtext/richtext";
@import "../components/futuropolis/search-facets/search-facets";
@import "../components/futuropolis/search-panel/search-panel";
@import "../components/futuropolis/search-results/search-results";
@import "../components/futuropolis/section/section";
@import "../components/futuropolis/select-dropdown/select-dropdown";
@import "../components/futuropolis/slider/slider";
@import "../components/futuropolis/social-networks/social-networks";
@import "../components/futuropolis/title/title";
@import "../components/futuropolis/truncate/truncate";
@import "../components/futuropolis/wishlist/wishlist";
@import "../components/futuropolis/newsletter-form/newsletter-form";
@import "../components/main/pdf/pdf";

body.print{
  .BookItem-title{
    font-size: 0.95rem !important;
  }
}
