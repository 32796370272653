// =========
// Utilities
// =========

// Lock on height
.Locked {
  height: 100%;
  overflow: hidden;
}

// Simply hide elements
.Hidden {
  display: none !important;
}

.VisuallyHidden {
  width: 0;
  height: 0;
  visibility: hidden;
}

// Titles style
.title {
  @if $theme == 'futuropolis' {
    color: $color-primary;
    @include font-bold($font-family: $font-family-title);
    font-size: rem-calc(26);
    line-height: 1.1;

    @media($mq-tablet) {
      font-size: rem-calc(47);
    }
    @media($mq-desktop) {
      font-size: rem-calc(52);
    }
  }
}

// Display rules
.desktopOnly {
  @media ($mq-upto-tablet) {
    display: none !important;
  }
}

.fromTabletOnly {
  @media ($mq-phone) {
    display: none !important;
  }
}

.mobileOnly {
  @media ($mq-from-tablet) {
    display: none !important;
  }
}

.d-none{
  display: none;
}
