// ======================================
// DEMO STYLE
// Simple styles for spacing demo content
// ======================================

// ---------
// Container
// ---------

.Demo {
  margin: 0;
  padding: 30px 15px;
  @if ($theme == 'bd') {
    background-color: #F8F6F1;
    color: #313D4F;
  } @else {
    background-color: $color-white;
    color: $color-dark;
  }

  @media($mq-from-tablet) {
    padding: 40px 30px;
  }

  &--theme {
    @if ($theme == 'jeunesse') {
      background-color: $color-dark;
      color: $color-white;
    }
    @else if ($theme == 'bd') {
      background-color: #313D4F;
      color: $color-white;
    }
    @else if ($theme == 'futuropolis') {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  &--gray {
    @if ($theme == 'futuropolis') {
      background-color: rgba($color-primary, .2);
      color: $color-darkPrimary;
    }
    @else if ($theme == 'jeunesse') {
      background-color: $color-lightGray;
      color: $color-dark;
    }
    @else if ($theme == 'bd') {
      background-color: #E4DBD0;
      color: #313D4F;
    }
  }
}


// ----------------------------
// Margin between text elements
// ----------------------------

.Demo summary {
  padding: .5em 0;
  cursor: pointer;
}

.Demo h1:not([class]):not(:last-child),
.Demo h2:not([class]):not(:last-child) {
  margin-bottom: 15px;
  @include font-bold($font-family: $font-family-title);
}

.Demo p:not([class]):not(:first-child) {
  margin-top: 15px;
}

.Demo-rowMargin {
  margin: 0 -12px;
}

.Demo-margin {
  margin: 12px 12px 9px;
}


// ------------------
// Centered container
// ------------------

.Demo-row {
  max-width: var(--max-width, 1200px);
  margin: 0 auto;
  text-align: left;

  & + & {
    margin-top: 45px;
  }

  &--medium {
    --max-width: 900px;
  }

  &--small {
    --max-width: 600px;
  }
}


// -------------------------------------------------
// Simple grids: auto columns or fixed column number
// -------------------------------------------------

.Demo-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

  & > * {
    margin: 0 !important;
  }

  &--2col {
    grid-template-columns: 1fr;

    @media($mq-from-tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}


// -------------------
// Blocks with borders
// -------------------

.Demo-block {
  margin: 10px 0;
  padding: 10px;
  border: solid 2px rgba(0, 0, 0, 0.2);

  &--gray {
    border-color: #ccc;
    background-color: rgba(200, 200, 200, 0.2);
  }

  &--yellow {
    border-color: #ed0;
    background-color: rgba(255, 255, 0, 0.16);
  }

  &--cyan {
    border-color: #4de;
    background-color: rgba(0, 255, 255, 0.12);
  }

  &--magenta {
    border-color: #eae;
    background-color: rgba(255, 0, 255, 0.08);
  }
}


// -----------------------------------------------
// Link and button style (for temporary work only)
// -----------------------------------------------

.Demo-link {
  color: var(--link-color, #0000d0);
  box-shadow: 0 2px 0 currentColor;
  text-decoration: none;

  &:hover, #{$keyboard} &:focus {
    background-color: var(--highlight-color, lime);
  }
}

.Demo-button {
  margin: 4px 2px;
  padding: 0.25em 0.5em;
  border-radius: 3px;
  border: solid 2px;
  color: var(--button-color, black);
  background-color: rgba(0, 0, 0, 0.08);

  &:hover, #{$keyboard} &:focus {
    background-color: var(--highlight-color, lime);
  }

  &--big {
    font-size: 115%;
    padding: 0.5em 1em;
  }
}