.WishList-main {
  min-height: 75vh;
}

.WishList {
  position: relative;
  .Button--secondary.Button--gray {
    padding: 12px 15px 9px;
  }

  .BookItem-deleteButton {
    max-width: 130px;
    padding: 10px 15px;
    margin-top: 10px;
    text-align: left;

    .icon {
      width: 20px;
      height: 20px;
      top: 9px;
      right: 11px;
    }
  }
  
  .BookItem-ean{
    display : block;
  }
}

// --------------
// WishList Modal
// --------------

.MessageModal.email-wishlist-modal {
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin: 0;

  @media($mq-from-tablet) {
    width: 80%;
    margin: 20px auto;
  }

  @media($mq-phone) {
    top: 0 !important;
  }
}

.WishList-header {
  display: flex;
  align-items: center;

  @media ($mq-phone) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
  }

  p {
    margin-right: auto;
  }

  button {
    margin-left: 20px;
    &:hover {
      color: #fff;
    }
  }
}

.WishList-warningMessage {
  padding: 15px 0;
  font-size: rem-calc(14);

  @media($mq-tablet) {
    font-size: rem-calc(15);
  }
  @media($mq-desktop) {
    padding: 25px 0;
    font-size: rem-calc(16);
  }
  @media print {
    display: none;
  }
}

#btn-email-wishlist {
  @media($mq-phone) {
    margin-top: 20px;
  }
}

.MessageModal.email-wishlist-modal {
  form {
    width: 100%;
    max-height: 100%;
    height: auto;
    padding: 20px;
    overflow-y: auto;

    @media($mq-from-tablet) {
      max-height: calc(100vh - 200px);
    }

    div {
      margin-bottom: 20px;
    }
  }

  #wishlist_email_subject {
    width: 100%;
  }
}
@media print {
  .sf-display-none {
    display: none !important;
  }
}
