// ========
// RichText
// ========

.RichText {

  // titles
  h1, h2, h3, h4, h5, h6 {
    &:not([class]),
    &[class*="text"] {
      position: relative;
      margin-bottom: 1rem;
      color: $color-dark;
      @include font-bold($font-family: $font-family-title);
      font-size: rem-calc(40);
      line-height: 1.1;
    }
    &:not(:first-child) {
      line-height: 1.5;
      margin-top: 1.3em;
    }
  }
  figure[class*="object"],
  a[name]:first-child {
    + h1, + h2, + h3, + h4, + h5, + h6 {
      margin-top: 0 !important;
    }
  }
  h2:not([class]),
  h2[class*="text"] {
    clear: both;
    font-size: rem-calc(38);

    @media ($mq-tablet) {
      font-size: rem-calc(44);
    }
    @media ($mq-desktop) {
      font-size: rem-calc(50);
    }
  }
  h3:not([class]),
  h3[class*="text"] {
    font-size: rem-calc(30);

    @media ($mq-from-tablet) {
      font-size: rem-calc(38);
    }
  }
  h4:not([class]),
  h4[class*="text"] {
    font-size: rem-calc(26);

    @media ($mq-from-tablet) {
      font-size: rem-calc(30);
    }
  }
  h5:not([class]),
  h5[class*="text"] {
    margin-bottom: 0.5rem;
    font-size: rem-calc(24);

    @media ($mq-from-tablet) {
      font-size: rem-calc(26);
    }
  }
  h6:not([class]),
  h6[class*="text"] {
    margin-bottom: 0.5rem;
    font-size: rem-calc(24);
  }

  // paragraph
  p:not([class]),
  p[class*="text"] {
    margin-bottom: 1rem;
    font-size: rem-calc(14);
    line-height: 1.5;

    @media($mq-from-tablet) {
      font-size: rem-calc(16);
    }
  }

  strong {
    font-weight: 700;
  }

  // lists
  ul:not([class]),
  ol:not([class]) {
    margin-bottom: 1.2rem;
    padding: 0;
    text-align: initial;

    ul, ol {
      margin-top: 0.5rem;
      margin-bottom: 0;
      color: $color-primary;
    }

    li {
      overflow: hidden;
      position: relative;
      @include font-base;
      font-size: rem-calc(18);
    }
  }

  ul:not([class]) {
    & > li {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: .5em;
        left: 0;
        border: 2px solid $color-dark;
        border-radius: 50%;
      }

      ul {
        @media($mq-from-tablet) {
          margin-left: 30px;
        }
      }

      ul > li::before {
        border-color: $color-primary;
      }
    }
  }

  ol:not([class]) {
    counter-reset: listnum;

    & > li {
      padding-left: 30px;
      list-style-type: none;

      &::before {
        content: counters(listnum, ".") ".";
        counter-increment: listnum;
        margin-left: -30px;
        margin-right: 10px;
        font-weight: 700;
      }

      ol {
        @media($mq-from-tablet) {
          margin-left: 20px;
        }
      }

      ol > li {
        &::before {
          content: counters(listnum, ".") " ";
          color: inherit;
        }

        ol > li {
          padding-left: 30px;
        }
      }
    }
  }

  // link, btn, download
  a:not([class]),
  a[class=""],
  a[class*="link"],
  button[type="button"]:not([class]) {
    color: inherit;
    font-family: inherit;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: inherit;
    text-underline-position: under;
    transition: color .25s ease-in-out,
                text-decoration-color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      color: $color-darkGreige;
      text-decoration-color: $color-darkGreige;
    }
  }

  .Button, .button {
    margin-bottom: 0;
  }

  // blockquote
  blockquote {
    position: relative;
    margin: 2rem 0;
    font-style: italic;
    @include font-title;
    font-size: rem-calc(24);

    p {
      margin-bottom: 0 !important;

      &:first-child::before {
        content: '“';
      }
      &:first-child::after {
        content: '”';
      }
    }

    strong,
    em {
      display: block;
      font-style: normal;
      line-height: 1.3;
    }
    strong {
      margin-top: .5rem;
      font-size: rem-calc(20);
    }
    em {
      color: $color-darkGreige;
      @include font-base;
      font-size: rem-calc(14);
    }
  }

  // images
  figure {
    margin-bottom: 2rem;

    @media($mq-phone) {
      text-align: center;
    }
  }

  figcaption {
    padding-top: 10px;
    color: $color-primary;
    @include font-base;
    font-size: rem-calc(13.3);
    line-height: 1.6;
  }

  // table
  table {
    width: 100%;
    margin: 2em 0;
    border: 1px solid $color-white;
    border-collapse: collapse;
    line-height: 1.7;

    .Section--gray & {
      border: 1px solid $color-lightenGray;
    }

    caption {
      margin-bottom: 0.5rem;
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
    }

    thead tr, thead th {
      background-color: $color-dark;
      color: $color-white;
    }

    tbody tr {
      &:nth-child(even) {
        background-color: $color-lightGreige;
      }
    }

    th {
      border: 0;
      padding: 25px;
      text-align: initial;
      font-weight: 400;
      color: $color-white;
      background-color: $color-dark;
      font-size: rem-calc(18);
    }

    td {
      padding: 25px;
      border: 1px solid $color-white;
      font-size: rem-calc(18);
    }

    p:not([class]) {
      margin-bottom: 0.5rem !important;
      font-size: rem-calc(18);
    }

    figure {
      margin-bottom: 0;
    }
  }

  // COLOR

  .brown-text {
    color: $color-brown;
  }

  // -----------------
  // eZ custom classes
  // -----------------

  // Image position
  .ez-embed-type-image {
    &.align-left {
      text-align: left;

      figure {
        margin-right: 2.75rem;

        @media($mq-from-tablet) {
          float: left;
        }
      }
    }

    &.align-center {
      text-align: center;
    }

    &.align-right {
      @media($mq-from-tablet) {
        text-align: right;
      }

      figure {
        margin-left: 2.75rem;

        @media($mq-from-tablet) {
          float: right;
        }
      }
    }
  }

  // Text type
  .small-text p {
    color: $color-primary;
    @include font-base;
    font-size: rem-calc(13.3);
    line-height: 1.6;
  }

  // Title levels
  .big-title {
    @include font-light;
    font-size: rem-calc(35);
    line-height: 1.1;

    @media($mq-from-tablet) {
      font-size: rem-calc(55);
    }
  }

  .bold-title {
    @include font-semibold;
    font-size: rem-calc(26);
    line-height: 1.2;
  }

  .thin-title {
    @include font-light;
    font-size: rem-calc(26);
    line-height: 1.2;
  }

  // Download button
  .download-button {
    @extend .Button;
    @extend .Button--primary;
    z-index: 1;

    &:hover, #{$keyboard} &:focus {
      color: $color-white;
    }
    &::after {
      z-index: -1;
    }
  }

  // Video embed alignment
  .video-embed {
    text-align: center;
  }

  // Highlight
  .highlight-content {
    margin: 2em 0;
    padding: 1.75em 2em 1em;
    background-color: $color-lightGreige;
    border: 1px solid rgba($color-darkGreige, .15);
    border-radius: 3px;

    .Section--gray & {
      background-color: rgba($color-lightGray, .5);
      border: 1px solid rgba($color-gray, .5);
    }

    @media($mq-from-tablet) {
      padding: 2.75em 3em 2em;
    }
  }
}
