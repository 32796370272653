// ===========
// Breadcrumbs
// ===========

.Breadcrumbs {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $color-white;
  border-top: 1px solid $color-primary;
  transition: opacity .25s ease-in-out .1s;
  z-index: 9;

  // Style variant
  .Page.Category &,
  .Page.Contributor &,
  .Page.Collection & {
    position: relative;
  }

  @media($mq-phone) {
    z-index: 1;

    // Gradient effect
    &::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 100%;
      background: linear-gradient(to right, rgba($color-white, 0), $color-white);
      top: 0;
      right: 0;
    }
  }

  @media print {
    display: none;
  }

  .Wrapper {
    padding-top: 5px !important;
    padding-bottom: 3px !important;
  }

  ul {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% + 4px);
    margin: 0 -2px;

    @media($mq-phone) {
      width: calc(100% + 40px);
      margin: 0 -20px;
      padding: 0 15px;
      overflow-x: auto;
    }
  }

  li {
    display: flex;
    align-items: center;
    padding-top: 1px;

    @media($mq-phone) {
      &:last-child {
        padding-right: 20px;
      }
    }

    &:not(:last-child) {
      &::after {
        content: '|';
        margin: 0 4px;
        color: $color-primary;
      }

      a {
        @include font-italic;
        @include text-underline;
      }
    }
    &:last-child a {
      @include font-medium;
      cursor: text;
    }

    .icon {
      width: 7px;
      height: 7px;
      margin: 0 3px 1px;
      transform: rotate(180deg);
    }
  }

  a {
    padding: 8px 2px;
    color: $color-darkPrimary;
    font-size: rem-calc(10);
    line-height: 1;
    white-space: nowrap;

    @media($mq-tablet) {
      font-size: rem-calc(12);
    }
    @media($mq-desktop) {
      font-size: rem-calc(14);
    }
  }
}
