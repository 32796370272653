// ==========
// Books list
// ==========

.BooksList {
  z-index: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @media($mq-phone) {
    flex-direction: column;
    justify-content: center;
  }
  @media($mq-from-tablet) {
    margin: 0 -22px;
  }

  @media print {
    display: table;
    margin: 10px 1px 0px 1px;
  }

  li {
    margin: 0 auto;

    @media($mq-from-tablet) {
      margin: 0 22px;
    }

    @media print {
      display: inline-table;
      vertical-align: top;
      margin: 0 -1px;
    }
  }
}

.BooksList > li {
  position: relative;
  padding-top: 10px;
  padding-bottom: 30px;
}

.BooksList-button {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 25px 0 75px;

  .Button {
    z-index: 1;
  }
}
