
.newsletter-form{
	.formHeader{
		display : none;
	}
	.formContent{
		.formTitle,.formText,.formParagraph{
			margin-bottom : 1rem;
		}
	}
	.formTextInput > input{
		width : 300px;
	}
	.formTextLabel{
		margin-bottom: 0.5rem;
	}
	a {
		position: relative;
		padding: 3px 0 5px;
		color: $color-darkPrimary;
		font-size: rem-calc(14);
		@include font-base;
		transition: color .25s ease-in-out,
		padding .25s ease-in-out;

		@media($mq-from-tablet) {
			height: 30px;
			font-size: rem-calc(16);
		}

		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 3px;
			left: 0;
			bottom: 3px;
			background-color: $color-darkPrimary;
			pointer-events: none;
			transition: height .25s ease-in-out;

			@media($mq-from-tablet) {
				bottom: 2px;
			}
		}
	}
	.formSubmit{
		text-align : left;
		a{
			height: 28px;
			cursor: pointer;
		}
	}
}

