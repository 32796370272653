// ==========
// Page style
// ==========

.Page {
  position: relative;

  @media($mq-from-tablet) {
    .Breadcrumbs {
      z-index: 1;
    }
  }

  .Section:nth-child(1) {
    padding-bottom: 1px;
    //padding-bottom: 45px; ToDo: undo if any regression
  }

  .Section:nth-child(2) {
    position: relative;
    background-color: $color-lightGreige;

    & > * {
      z-index: 1;
      position: relative;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      top: 314px;
      left: 50%;
      bottom: 0;
      background-color: $color-white;
      transform: translateX(-50%);
    }
  }
}