// =======================
// Websites List component
// =======================

.WebsitesList-mainTitle {
  margin-bottom: 10px;
  color: $color-dark;
  @include font-bold($font-family: $font-family-title);
  font-size: rem-calc(30);
  text-align: center;

  @media($mq-tablet) {
    margin-bottom: 15px;
    font-size: rem-calc(35);
  }
  @media($mq-desktop) {
    margin-bottom: 20px;
    font-size: rem-calc(40);
  }
}

.WebsitesList-container {
  @media($mq-phone) {
    overflow: visible;
  }
}

.WebsitesList-list {
  display: flex;
  padding: 20px 0 40px;

  @media($mq-desktop) {
    justify-content: center;
  }
}

.WebsitesList-item {
  position: relative;

  @media($mq-phone) {
    width: 93%;
  }
  @media($mq-desktop) {
    max-width: 280px;
    width: 100%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.WebsitesList-image {
  object-fit: cover;
  width: 100%;
  margin-bottom: 20px;
}

.WebsitesList-title {
  @include hover-all;
  display: block;
  margin-bottom: 10px;
  @include font-semibold;
  font-size: rem-calc(15);
  transition: color .25s ease-in-out;

  @media($mq-tablet) {
    margin-bottom: 15px;
    font-size: rem-calc(16);
  }
  @media($mq-desktop) {
    margin-bottom: 20px;
    font-size: rem-calc(17);
  }

  &:hover, #{$keyboard} &:focus {
    color: $color-red;

    & ~ .WebsitesList-button .icon {
      color: $color-red;
    }
  }
}

.WebsitesList-description {
  z-index: 1;
  position: relative;
  margin-bottom: 8px;
  @include font-title;
  font-size: rem-calc(15);

  @media($mq-tablet) {
    margin-bottom: 10px;
    font-size: rem-calc(16);
  }
  @media($mq-desktop) {
    margin-bottom: 12px;
    font-size: rem-calc(17);
  }
}

.WebsitesList-button {
  display: flex;
  align-items: center;
  color: $color-darkGray;
  font-size: rem-calc(10);

  @media($mq-from-tablet) {
    font-size: rem-calc(12);
  }

  .icon {
    width: 28px;
    height: 28px;
    color: $color-dark;
    transition: color .25s ease-in-out;

    @media($mq-from-tablet) {
      width: 32px;
      height: 32px;
      margin-top: 1px;
    }
  }
}


.WebsitesList-mainTitle {
  display: block;
  margin: 0;
}
