/*! Init page styles */

// Default box model
*,
:before,
:after {
  box-sizing: border-box;
}

// Additionnal reset styles for very common stuff
// Normalize tries to only remove differences between browsers.
// If we want an actual reset, we need to reset the styles of
// just a handful of elements.

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
}

p,
ul,
ol,
figure {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

// Reset button styles.
// It takes a bit of work to achieve a neutral look.
button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  // show a hand cursor on hover
  cursor: pointer;
}

details > summary {
  cursor: pointer;
  user-select: none;
}

input[type="search"] {
  -webkit-appearance: none;
}

// Here we contradict Normalize
fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

// Force images and frames to fit their container at most
img,
iframe {
  max-width: 100%;
}

// Other small fixes
::-ms-clear {
  display: none;
}

// Document
body {
  font-family: $font-family-base;
  font-size: rem-calc(14);
  line-height: 1.375;
  color: $color-dark;
  background-color: $color-white;

  @media($mq-from-tablet) {
    font-size: rem-calc(16);
  }
}

main {
  min-height: 75vh;
}

@media print {
  #klaro { display: none; }
}
