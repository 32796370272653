// ================
// Header Component
// ================

// ----------
// Header-top
// ----------

.Header-top {
  position: relative;
  padding: 5px 0;
  background-color: $color-white;
  color: $color-dark;

  @media ($mq-phone) {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
    height: 72px;
  }

  @media($mq-tablet) {
    padding: 15px 0;
  }
  @media($mq-desktop) {
    padding: 22px 0;
  }

  .Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ($mq-phone) {
      height: 100%;
    }
  }
}

// Logo
.Header-logo a {
  display: inline-block;

  .icon {
    width: 150px;
    height: 43px;

    @media($mq-tablet) {
      width: 206px;
      height: 60px;
    }
    @media($mq-desktop) {
      width: 245px;
      height: 71px;
    }
  }
}

// Menu burger
.Header-menuBurger {
  position: relative;
  width: 52px;
  height: 52px;
  margin: 0 35px;
  padding: 11px 18px;
  transition: transform .25s ease-in-out;
  cursor: pointer;

  @media($mq-phone) {
    margin: 0 -10px 0 8px;
  }
  @media($mq-tablet) {
    margin: 0 20px;
  }

  @media($mq-from-tablet) {
    margin-right: 20px;
  }

  &:hover, &[aria-expanded="true"] {
    transform: scale(1.2);
  }

  span {
    position: absolute;
    display: block;
    width: calc(100% - 20px);
    height: 3px;
    margin: 5px 0;
    left: 10px;
    background: $color-darkPrimary;
    opacity: 1;
    transform: rotate(0);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 10px;
    }
    &:nth-child(2), &:nth-child(3) {
      top: 20px;
    }
    &:nth-child(4) {
      top: 30px;
    }
  }
  &[aria-expanded="true"] span {
    &:nth-child(1) {
      width: 0;
      top: 18px;
      left: 50%;
    }
    &:nth-child(2) {
      transform: rotate(45deg);
    }
    &:nth-child(3) {
      transform: rotate(-45deg);
    }
    &:nth-child(4) {
      width: 0;
      top: 18px;
      left: 50%;
    }
  }
}

// Search
.Header-search {
  flex: 1;
  padding: 0 25px;

  @media($mq-upto-tablet) {
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
}

.Header-searchContainer {
  &--fromTablet {
    width: 100%;

    @media($mq-phone) {
      display: none;
    }
  }
  &--phone {
    @media($mq-from-tablet) {
      display: none;
    }
  }

  form {
    @media($mq-tablet) {
      display: flex;
      justify-content: flex-end;
    }
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: rem-calc(17);

    @media($mq-upto-tablet) {
      justify-content: flex-end;
      max-width: 80%;
      margin-right: -12px;
    }
  }

  input {
    max-width: 370px;
    width: 100%;
    margin: 0 12px;
    padding: 3px 0;
    border: 0;
    border-bottom: 1px solid;

    &::placeholder {
      color: $color-primary;
    }
  }

  .icon {
    width: 30px;
    height: 30px;
  }
}

.Header-searchButton {
  position: relative;
  margin-right: -14px;
  padding: 11px 14px;
  transition: transform .25s ease-in-out;

  &:hover, &[aria-expanded="true"] {
    transform: scale(1.2);
  }

  .icon {
    width: 26px;
    height: 26px;
    transition: opacity .25s ease-in-out,
                transform .25s ease-in-out;

    @media($mq-from-tablet) {
      width: 30px;
      height: 30px;
    }
  }
  &[aria-expanded="true"] .icon {
    opacity: 0;
    transform: scale(0);
  }

  &:hover, &:focus {
    outline: 0;
  }
}

.Header-searchCloseButton {
  position: absolute;
  width: 32px;
  height: 30px;
  top: 9px;
  left: 14px;
  opacity: 0;
  transform: scale(0);
  transition: opacity .25s ease-in-out,
              transform .25s ease-in-out;

  [aria-expanded="true"] & {
    opacity: 1;
    transform: scale(1);
  }

  span {
    position: absolute;
    display: block;
    width: 28px;
    height: 3px;
    margin: 10px 0;
    top: 4px;
    left: -2px;
    background: $color-dark;

    &:first-child {
      transform: rotate(45deg);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
}

// Links
.Header-links {
  @media($mq-upto-tablet) {
    display: none;
  }

  ul {
    margin-right: -10px;

    a, button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px 10px 3px;
      float : right;
      color: $color-darkPrimary;
      @include text-underline;

      //&:hover, #{$keyboard} &:focus {
      //  text-decoration: underline;
      //}

      .icon {
        width: 20px;
        height: 20px;
        margin-top: -1px;
        margin-left: 10px;
        color: $color-primary;
      }
    }
  }
}


// -------------
// Header-bottom
// -------------

.Header-bottom {
  padding: 10px 0 8px;
  background-color: $color-primary;
  color: $color-white;

  @media($mq-upto-tablet) {
    display: none;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -12px;
  }

  li a {
    display: inline-block;
    padding: 12px;
    font-size: rem-calc(17);
    line-height: 1;

    @media('min-width: 1200px') {
      font-size: rem-calc(18);
    }

    &:hover, #{$keyboard} &:focus {
      text-decoration: underline;
    }
  }
}
