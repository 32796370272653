// ====================
// News Embed component
// ====================

.NewsEmbed {
  display: table;

  li:not(:first-of-type) & {
    margin-top: 20px;
  }
}


.NewsEmbed img {
  max-width: 330px;
  width: 100%;
  margin-bottom: 40px;

  @media('min-width: 1000px') {
    display: table-cell;
    float: left;
    margin-right: 60px;
  }
}

.NewsEmbed-text {
  margin-bottom: 40px;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
}


// ------
// Header
// ------

.RichText {
  p.NewsEmbed-title {
    @include font-bold($font-family: $font-family-title);
    font-size: rem-calc(30);
    line-height: 1.2;
  }

  p.NewsEmbed-subtitle {
    @include font-title;
    font-size: rem-calc(26);
    line-height: 1.2;
  }

  .NewsEmbed-content {
    p {
      line-height: 1.5;
    }
  }

  .NewsEmbed-footer {
    p {
      color: $color-primary;
      line-height: 1.5;
    }
  }

  .NewsEmbed-downloads {
    margin-top: 30px;
  }
}