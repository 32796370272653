// ==========
// Hero style
// ==========

.Hero {
  position: relative;

  &--noImage {
    .Section & .Wrapper {
      padding-bottom: 0;
    }
    .Hero-container {
      margin-bottom: 0;
    }
  }
}

.Hero-container {
  display: flex;
  margin: 10px 0 15px;
  padding: 30px;
  background-color: $color-white;

  @media('min-width: 900px') {
    margin: 5px 0 15px;
    padding: 50px;
  }
}

.Hero-imageCopyright {
  margin-top: 10px;
  color: $color-darkGray;
  font-size: rem-calc(12);

  @media($mq-from-tablet) {
    margin-bottom: -19px;
    font-size: rem-calc(14);
  }
}

.Hero-content {
  z-index: 1;
  position: relative;
  width: 100%;
  padding: 25px 20px 20px;
  background-color: $color-white;

  @media('min-width: 900px') {
    position: absolute;
    min-width: 500px;
    width: 60%;
    bottom: -40px;
    right: 0;
  }
  @media('min-width: 900px') and ('max-width: 1205px') {
    padding: 35px 45px 40px;
  }
  @media('min-width: 1205px') {
    padding: 50px 60px 55px;
  }
}

.Hero-image {
  position: relative;
}

.Hero-heading {
  margin-bottom: 15px;

  @media('min-width: 700px') and ('max-width: 899px') {
    margin-bottom: 20px;
  }
  @media('min-width: 900px') {
    margin-bottom: 25px;
  }
}

.Hero-title.title {
  font-size: rem-calc(22);

  @media($mq-tablet) {
    font-size: rem-calc(36);
  }
  @media($mq-desktop) {
    font-size: rem-calc(50);
  }
}

.Hero-textContainer {
  margin-bottom: 15px;

  @media($mq-from-tablet) {
    margin-bottom: 20px;
  }
}

.Hero-text {
  font-size: rem-calc(14);

  @media($mq-from-tablet) {
    font-size: rem-calc(16);
  }

  strong {
    @include font-medium;
  }
}

.Hero-links {
  margin-bottom: 20px;
}

.Hero-link a {
  font-size: rem-calc(14);
  text-decoration: underline;
  transition: color .25s ease-in-out;

  @media($mq-from-tablet) {
    font-size: rem-calc(16);
  }

  &:hover, #{$keyboard} &:focus {
    color: $color-darkGreige;
  }
}

// ----------------
// Category variant
// ----------------

.Category {
  .Hero-image {
    padding-top: 45%;

    @media('min-width: 900px') {
      padding-top: 37%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  // Allow scroll into view
  .Hero-text {
    overflow-y: auto;
  }
}

// -------------------------------
// Contributor, collection variant
// -------------------------------

.Contributor, .Collection {
  .Hero .Wrapper {
    padding-bottom: 0 !important;

    @media('max-width: 899px') {
      padding-top: 20px;
    }
  }
  .Hero--image .Wrapper {
    padding-top: 40px;

    @media('min-width: 899px') {
      padding-top: 70px;
    }
  }
  .Hero-container {
    @media('max-width: 899px') {
      flex-direction: column-reverse;
    }
  }
  .Hero-image {
    flex: 1 0 auto;
    margin-top: -60px;

    @media('max-width: 899px') {
      margin-bottom: 20px;
    }
    @media('min-width: 900px') {
      margin-top: -100px;
      margin-left: 50px;
    }

    img {
      width: 200px;

      @media('min-width: 900px') and ('max-width: 1099px') {
        width: 296px;
      }
      @media($mq-desktop) {
        width: 330px;
      }
    }
  }
}

// Contributors List variant
.Hero-alphabet {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 28px -6px 0;

  @media($mq-from-tablet) {
    margin: 40px -10px 0;
  }
}

.Hero-letter {
  a, p {
    margin: 0 3px;
    padding: 3px;
    font-size: rem-calc(20);

    @media($mq-from-tablet) {
      margin: 0 5px;
      padding: 5px;
      font-size: rem-calc(22);
    }
  }
  a {
    @include font-bold;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      text-decoration-color: $color-darkPrimary;
    }
  }
  p {
    color: rgba($color-darkPrimary, .7);
  }
  a ~ p {
    display: none;
  }
}