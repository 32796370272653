// =========
// News Item
// =========

.NewsItem-container {
  position: relative;
  transition: color .25s ease-in-out;

  &:hover, #{$keyboard} &:focus {
    .NewsItem-image {
      background-color: $color-darkPrimary;
    }
    .Button--large {
      background-color: darken($color-greige, 10%);
    }
  }
}

.NewsItem-type {
  margin-top: -20px;
  margin-bottom: 15px;
}

.NewsItem-image {
  position: relative;
  width: 100%;
  padding-top: 55%;
  background-color: transparent;
  transition: background-color .25s ease-in-out;

  @media($mq-from-tablet) {
    padding-top: 63%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    mix-blend-mode: exclusion;
  }

  &--video .icon {
    position: absolute;
    width: 50px;
    height: 50px;
    top: calc(50% + 3px);
    left: 50%;
    color: $color-white;
    transform: translate(-50%, -50%);
  }
}

.NewsItem-text {
  color: $color-darkPrimary;
  overflow: hidden;

  .Button {
    max-width: none;
  }
}

.NewsItem-title {
  display: block;
  width: 100%;
  padding-top: 20px;
  @include hover-all;
  @include font-title;
  font-size: rem-calc(18);
  text-align: left;

  @media($mq-tablet) {
    font-size: rem-calc(22);
  }
  @media($mq-desktop) {
    font-size: rem-calc(24);
  }
}

.NewsItem-dates {
  padding-top: 12px;
  font-size: rem-calc(12);
}