// =========================
// Social Networks Component
// =========================

.SocialNetworks {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin: 0 -7px -9px;

  @media($mq-from-tablet) {
    margin: 0 -8px -12px;
  }

  li a {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 34px;
    margin: 5px 7px;
    border: 3px solid $color-darkPrimary;
    border-radius: 50%;
    color: $color-darkPrimary;
    cursor: pointer;
    transition: background-color .25s ease-in-out,
                border-color .25s ease-in-out,
                color .25s ease-in-out;

    @media($mq-from-tablet) {
      width: 40px;
      height: 40px;
      margin: 0 8px 8px;
    }

    &:hover, #{$keyboard} &:focus {
      background-color: $color-darkPrimary;

      .icon {
        color: $color-white;
      }
    }

    .icon {
      position: absolute;
      width: 14px;
      height: 14px;
      margin: 7px;
      transition: color .25s ease-in-out;

      @media($mq-from-tablet) {
        width: 16px;
        height: 16px;
        margin: 9px;
      }
    }
  }

  // Theme variants
  &--light {
    li a {
      border-color: rgba($color-white, .5);
      color: $color-white;

      &:hover, #{$keyboard} &:focus {
        background-color: $color-white;
        border-color: $color-white;
        color: $color-dark;
      }
    }
  }

  &--darkGray {
    li a:hover, li a:focus {
      background-color: $color-darkGray;
      border-color: $color-darkGray;
      color: $color-white;
    }
  }

  &--dark {
    li a {
      border-color: $color-darkGray;
      color: $color-dark;

      &:hover, #{$keyboard} &:focus {
        background-color: $color-dark;
        border-color: $color-dark;
        color: $color-white;
      }
    }
  }

  // Type variant
  &--banner {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: 39px 0;
    background-color: $color-dark;
    border-bottom: 1px solid rgba($color-white, .5);
    color: $color-white;
    font-size: rem-calc(25);

    @media($mq-tablet) {
      font-size: rem-calc(30);
    }
    @media($mq-desktop) {
      font-size: rem-calc(35);
    }

    .Wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media($mq-phone) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    ul {
      display: flex;
      margin: 0 -10px;

      @media($mq-phone) {
        margin: 0 -7px -6px;
      }
    }

    li a {
      margin: 10px 7px 0;

      @media($mq-from-tablet) {
        width: 45px;
        height: 45px;
        margin: 12px 10px 0;

        .icon {
          width: 19px;
          height: 19px;
          margin: 12px;
        }
      }
    }
  }
}

.SocialNetworks-text {
  @media($mq-from-tablet) {
    margin-right: 35px;
  }
}