// =====
// Fonts
// =====

$font-root: "../../public/fonts/";

$font-serif-map: () !default;
$font-sans-map: () !default;

@each $url, $params in $font-sans-map {
  @font-face {
    font-family: $font-sans-name;
    src: url($font-root + $url + '.woff2') format('woff2'),
         url($font-root + $url + '.woff') format('woff');
    font-weight: nth($params, 1);
    font-style: nth($params, 2);
  }
}

@each $url, $params in $font-serif-map {
  @font-face {
    font-family: $font-serif-name;
    src: url($font-root + $url + '.woff2') format('woff2'),
         url($font-root + $url + '.woff') format('woff');
    font-weight: nth($params, 1);
    font-style: nth($params, 2);
  }
}

@font-face {
  font-family: 'Vibur';
  src: url('./../../public/fonts/vibur/Vibur-Regular.ttf') format('truetype');
}

$viburFont : 'Vibur', sans-serif;
