// ================
// Footer component
// ================


.Footer {
  color: $color-white;
  background-color: $color-primary;
}

.Footer-top {
  padding: 27px 0 10px;

  @media($mq-tablet) {
    padding: 62px 0 50px;
  }
  @media($mq-desktop) {
    padding: 80px 0 55px;
  }
}

.Footer-bottom {
  position: relative;
  background-color: $color-darkPrimary;

  .Wrapper {
    display: flex;

    @media($mq-phone) {
      flex-direction: column-reverse;
    }
    @media($mq-from-tablet) {
      justify-content: space-between;
      align-items: center;
    }
  }
}

.Footer-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media($mq-phone) {
    flex-direction: column;
  }

  .Footer-top & {
    justify-content: space-between;
    width: 100%;

    & > li {
      @media($mq-phone) {
        width: 100%;
      }
      @media($mq-from-tablet) {
        margin-bottom: 10px;
      }
    }
  }

  .Footer-bottom & {
    margin: 0 -15px;
    padding-top: 22px;
    padding-bottom: 22px;

    li:not(:last-child) {
      @media($mq-phone) {
        margin-bottom: 10px;
      }
      @media($mq-from-tablet) {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 2px;
          height: 37%;
          top: 52%;
          right: -1px;
          background-color: rgba($color-white, .5);
          transform: translateY(-50%);
        }
      }
    }

    li a {
      display: inline-block;
      padding: 6px 15px 2px;
      font-size: rem-calc(14);
      @include text-underline;

      @media('min-width: 700px') and ('max-width: 999px') {
        font-size: rem-calc(13);
      }
    }
  }
}

.Footer-item {
  &--main {
    @media($mq-phone) {
      margin-bottom: 25px !important;
    }
    @media($mq-tablet) {
      margin-bottom: 40px !important;
    }
    @media($mq-upto-tablet) {
      width: 100%;
    }
    @media($mq-desktop) {
      flex: 1;
    }
  }

  &--columns {
    @media($mq-tablet) {
      width: 75%;
    }
    @media($mq-desktop) {
      flex: 2;
    }

    & > ul {
      @media($mq-from-tablet) {
        display: flex;
      }
    }
  }

  &--social {
    @media($mq-tablet) {
      width: 25%;
    }
    @media($mq-desktop) {
      flex: 1;
    }
  }
}

.Footer-listItem {
  @media($mq-phone) {
    cursor: pointer;
  }
  @media($mq-tablet) {
    flex: 1;
  }
  @media($mq-desktop) {
    flex: 1;
  }

  // Close state, default
  .Footer-listTitle .icon {
    transform: rotate(-90deg);
  }

  // Open state
  &.is-active .Footer-listTitle .icon {
    transform: rotate(-270deg);
  }
}

.Footer-listTitle {
  position: relative;
  display: block;
  padding-bottom: 15px;
  font-size: rem-calc(18);

  @media($mq-from-tablet) {
    font-size: rem-calc(20);
  }

  .icon {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 22px;
    right: 0;
    transition: transform .25s ease-out;

    @media($mq-from-tablet) {
      display: none;
    }
  }

  @media($mq-phone) {
    padding-top: 16px;
    padding-bottom: 13px;

    &:focus {
      outline: none;
    }

    &::before {
      content: '';
      position: absolute;
      height: 1px;
      top: 0;
      left: -20px;
      right: -20px;
      background-color: rgba($color-white, .5);
    }
  }
}

.Footer-sublist {
  @media($mq-phone) {
    &:not(.Footer-sublist--social) {
      display: none; // Required for Foundation's Accordion init
    }

    &:not(.Footer-sublist--social) li:last-child {
      margin-bottom: 6px;
    }
  }

  li a {
    display: inline-block;
    margin-bottom: 3px;
    font-size: rem-calc(18);
    @include text-underline;

    @media($mq-from-tablet) {
      font-size: rem-calc(20);
    }
  }

  &:not(.Footer-sublist--social) li:first-child {
    margin-top: 12px;
  }

  &:not(.Footer-sublist--social) li:last-child {
    margin-bottom: 12px;
  }

  // Columns variant
  &--columns {
    li {
      margin: 0 -5px;

      a {
        padding: 5px;
      }
    }
  }

  // Social networks variant
  &--social {
    display: flex;
    width: calc(100% + 10px);
    margin: 0 -5px;

    @media($mq-tablet) {
      flex-wrap: wrap;
      max-width: 100px;
    }

    li a {
      position: relative;
      width: 34px;
      height: 34px;
      margin: 8px 5px;
      border: 2px solid $color-white;
      border-radius: 50%;
      transition: background-color .25s ease-in-out, border-color .25s ease-in-out, color .25s ease-in-out;

      @media($mq-tablet) {
        margin: 8px 5px -3px;
      }
      @media($mq-from-tablet) {
        width: 40px;
        height: 40px;
        border: 3px solid $color-white;
      }

      &:hover, #{$keyboard} &:focus {
        background-color: $color-white;
        border-color: $color-white;
        color: $color-primary;
      }

      .icon {
        position: absolute;
        width: 16px;
        height: 16px;
        margin: 7px;

        @media($mq-from-tablet) {
          width: 18px;
          height: 18px;
          margin: 8px;
        }
      }
    }
  }
}

.Footer-logo {
  position: relative;
  display: inline-block;

  @media($mq-desktop) {
    top: -15px;
  }

  .icon {
    width: 173px;
    height: 53px;
  }
}

.Footer-legal {
  padding: 6px 0 2px;
  font-size: rem-calc(14);

  @media($mq-phone) {
    position: relative;
    padding: 25px 0 23px;

    &::before {
      content: "";
      position: absolute;
      height: 1px;
      top: 0;
      left: -20px;
      right: -20px;
      background-color: rgba($color-white, 0.5);
    }
  }
  @media('max-width: 999px') {
    font-size: rem-calc(13);
  }
}

.Footer-backtoTop {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -60px;
  right: 20px;
  background-color: $color-darkPrimary;
  transition: background-color .25s ease-in-out, color .25s ease-in-out;

  &:hover, #{$keyboard} &:focus {
    background-color: $color-white;
    color: $color-primary;
  }

  @media($mq-desktop) {
    width: 65px;
    height: 65px;
    top: -85px;
  }

  .icon {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);

    @media($mq-desktop) {
      width: 30px;
      height: 30px;
    }
  }
}