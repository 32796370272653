// =======
// Filters
// =======

.Filters {
  margin: -30px 0 0;
  padding: 30px 0 15px;

  & > * {
    z-index: 1;
    position: relative;

    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .swiper-slide {
    width: auto;
  }

  .SelectDropdown {
    float: left;
    max-width: 265px;
    width: 100%;
  }

  .SearchResults-resetTags {
    float:right;
  }
}

.Filters-header {
  margin-bottom: 15px;
  border-bottom: 1px solid $color-lightGray;
  color: $color-dark;
  font-size: rem-calc(26);
  @include font-semibold;
}

.Filters-filter {
  display: flex;
  align-items: center;

  @media($mq-phone) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.Filters-filterTitle {
  flex: 1 0 auto;
  margin-right: 15px;
  color: $color-darkGray;
  font-size: rem-calc(26);

  @media($mq-phone) {
    margin-bottom: 5px;
  }
}

.Filters-filterList {
  width: 100%;

  .SearchResults-tagsContainer {
    position: relative;
    top:0;

    .SearchResults-taglist ul {
      position: relative;
    }

  }
}