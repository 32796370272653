// ============
// Circles List
// ============

.CirclesList {
  position: relative;
  z-index: 0;
}

.CirclesList-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -17px;
  padding: 20px 0;

  & > li {
    position: relative;
    width: 70%;

    @media($mq-desktop) {
      width: 20.3%;
    }
    @media('min-width: 900px') and ('max-width: 1100px') {
      width: 25%;
    }
    @media('min-width: 700px') and ('max-width: 900px') {
      width: 35%;
    }
    @media('min-width: 500px') and ('max-width: 700px') {
      width: 45%;
    }
    @media($mq-phone) {
      margin: 0 auto;
    }
  }
}

.CirclesList-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 17px 30px;
}

.CirclesList-title {
  @include hover-all;
  @include font-bold($font-family: $font-family-title);
  font-size: rem-calc(20);
  text-align: center;
  transition: color .25s ease-in-out;
}

.CirclesList-image {
  position: relative;
  width: 100%;
  height: 290px;
  margin-bottom: 20px;
  background-color: transparent;
  transition: background-color .25s ease-in-out;

  &.noCover {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: auto;
      position: inherit;
    }
  }

  img {
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: exclusion;
    object-fit: cover;
  }

  .CirclesList-item:hover & {
    background-color: $color-darkPrimary;
  }
}



.CirclesList .Slider .Slider-buttonPrev ,
.CirclesList .Slider .Slider-buttonNext {
  transform: rotate(180deg);
}
