.Presspage-content {
  form {
    width: 100%;
    max-height: 100%;
    height: auto;
    padding: 20px;
    overflow-y: auto;


    input {
      width: 100%;

      @media ($mq-desktop) {
        width: 300px;
      }
    }

    div {
      margin-bottom: 20px;
    }
  }

  .Presspage-formLogin, .Presspage-formValidation {
    margin-top: 30px;
  }

  .Presspage-form .Title {
    font-weight: bold;
  }
}

.Presspage-main {
  padding-top: 20px;
}

.proarea-generalMessage {
  margin-top: 30px;
}
