#loader-screen{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: white;
	z-index: 10;
	animation: fadeIn 0.5s;
}

.loader {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: inline-block;
	border-top: 3px solid var(--swiper-theme-color);
	border-right: 3px solid var(--swiper-theme-color);
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0%;
	}
	100% {
		opacity: 100%;
	}
}
