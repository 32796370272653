// ===============
// Homepage Slider
// ===============

.HomepageSlider {
  background-color: $color-lightPrimary;

  @media('min-width: 900px') {
    padding-bottom: 30px;
  }
}

.HomepageSlider-container {
  overflow: visible;
}

.HomepageSlider-wrapper {
  @media('min-width: 900px') {
    height: auto !important;
  }
}

.HomepageSlider-slide {
  position: relative;
  flex-direction: column;
  height: auto;
}

.HomepageSlider-content {
  position: relative;
  flex: 1;
  width: 100%;
  height: auto;
  padding: 25px 20px 20px;
  background-color: $color-white;

  @media('min-width: 900px') {
    position: absolute;
    min-width: 500px;
    width: 42%;
    bottom: -40px;
    right: 0;
  }
  @media('min-width: 700px') and ('max-width: 1204px') {
    padding: 35px 45px 40px;
  }
  @media('min-width: 1205px') {
    padding: 50px 60px 55px;
  }

  &:hover, #{$keyboard} &:focus {
    .Button {
      color: $color-white;
      padding: 3px 8px 5px;

      &::after {
        height: 100%;
        transition: height .25s ease-in-out;
      }
    }
  }
}

.HomepageSlider-image {
  position: relative;
  padding-top: 45%;

  @media('min-width: 900px') {
    padding-top: 37%;
  }
  // Useful when padding-top hack doesn't work
  // e.g. for older Firefox
  @media('min-width: 900px') and ('max-width: 1099px') {
    min-width: 330px;
  }
  @media('min-width: 1100px') {
    min-width: 435px;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

.HomepageSlider-heading {
  margin-bottom: 15px;

  @media($mq-tablet) {
    margin-bottom: 20px;
  }
  @media($mq-desktop) {
    margin-bottom: 25px;
  }
}

.HomepageSlider-title.title {
  font-size: rem-calc(22);

  @media($mq-tablet) {
    font-size: rem-calc(36);
  }
  @media($mq-desktop) {
    font-size: rem-calc(50);
  }

  & + .HomepageSlider-subtitle {
    margin-top: 6px;

    @media($mq-from-tablet) {
      margin-top: 8px;
    }
  }

  a {
    @include hover-all;
    transition: color .25s ease-in-out;

    &:hover, #{$keyboard} &:focus {
      color: $color-darkPrimary;
    }
  }
}

.HomepageSlider-subtitle {
  @include font-medium;
  color: $color-darkGreige;
  font-size: rem-calc(14);

  @media($mq-from-tablet) {
    font-size: rem-calc(18);
  }
}

.HomepageSlider-text {
  margin-bottom: 15px;
  color: $color-darkPrimary;
  font-size: rem-calc(14);

  @media($mq-from-tablet) {
    margin-bottom: 20px;
    font-size: rem-calc(16);
  }
}


// -------
// Options
// -------

.HomepageSlider-controls {
  position: absolute;
  right: 0;
  pointer-events: none;

  @media('max-width: 900px') {
    padding-top: calc(45% - 50px);
    right: 0;
  }
  @media('min-width: 900px') and ('max-width: 1205px') {
    left: calc(100% - 700px);
    bottom: 0;
  }
  @media('min-width: 1205px') {
    left: calc(58% - 200px);
    bottom: 0;
  }

  ul {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 100px;
    padding: 30px 40px;
    background-color: $color-darkPrimary;
    color: $color-white;
    pointer-events: auto;

    @media('max-width: 900px') {
      width: 120px;
      height: 50px;
      padding: 12px 17px;
    }
  }
}

.HomepageSlider-fraction {
  position: relative;
  left: auto;
  bottom: auto;
  font-size: rem-calc(16);

  @media('max-width: 899px') {
    font-size: rem-calc(14);
    padding-top: 4px;
  }
}

.HomepageSlider-button {
  &::after {
    content: none;
  }
  &#{Prev} .icon {
    transform: rotate(180deg);
  }
  &:hover .icon,
  #{$keyboard} &:focus .icon {
    color: $color-white;
  }

  .icon {
    width: 19px;
    height: 37px;
    color: $color-primary;
    transition: color .25s ease-in-out;
  }

  &#{Prev} {
    left: 36px;

    @media('max-width: 900px') {
      left: 8px;
      transform: scale(.75);
    }
  }
  &#{Next} {
    right: 36px;

    @media('max-width: 900px') {
      right: 8px;
      transform: scale(.75);
    }
  }
}
