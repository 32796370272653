// ============
// Search Panel
// ============

.SearchPanel {
  max-height: calc(100vh - 154px);
  overflow-x: hidden;
  overflow-y: auto;

  @media($mq-from-tablet) {
    display: flex;
  }
  @media($mq-tablet) {
    max-height: calc(100vh - 90px);
  }
  @media($mq-desktop) {
    max-height: calc(100vh - 115px);
  }
}

.SearchPanel-item {
  flex: 1;
  padding: 25px 0;

  &:first-child {
    @media($mq-phone) {
      padding-top: 5px;
      padding-bottom: 30px;
      border-bottom: 1px solid $color-darkGray;
    }
    @media($mq-tablet) {
      padding-right: 25px;
    }
    @media($mq-desktop) {
      padding-right: 50px;
    }
  }
  &:last-child {
    @media($mq-phone) {
      padding-top: 22px;
    }
    @media($mq-tablet) {
      padding-left: 25px;
    }
    @media($mq-desktop) {
      padding-left: 50px;
    }
  }
}

.SearchPanel-title {
  padding: 10px 0;
  font-size: rem-calc(20);
  @include font-bold($font-family: $font-family-title);
}

.SearchPanel-subItem {
  padding-bottom: 30px;

  &:not(:first-child) {
    padding-top: 22px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $color-darkGray;
  }
}

.SearchPanel-list {
  padding-bottom: 18px;
}

.SearchPanel-listItem a {
  display: block;
  padding: 3px 0;
  font-size: rem-calc(16);
  @include text-underline($color: $color-darkGreige);
}


// -------------
// Phone variant
// -------------

.SearchPanel-form {
  padding-top: 25px;
  padding-bottom: 20px;

  @media($mq-from-tablet) {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    padding: 10px 2px 10px 12px;
    border: 1px solid $color-darkGray;
    border-radius: 3px;
  }

  input {
    @include font-medium;
    width: 100%;
    margin: 0 12px;
    padding: 4px 0;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $color-darkGray;
    border-radius: 0;
    color: $color-dark;
    font-size: rem-calc(14);
    line-height: 26px;
    transition: border-color .25s ease-in-out;

    &:hover, &:focus {
      outline: 0;
    }

    &::placeholder {
      color: $color-darkGray;
      font-weight: 400;
      transition: color .25s ease-in-out;
    }

    &:hover, #{$keyboard} &:focus {
      border-color: $color-dark;

      &::placeholder {
        color: $color-dark;
      }
    }
  }

  .icon {
    width: 25px;
    height: 25px;
  }
}
