// ================
// Button Component
// ================


.Button {
  display: inline-table;
  padding: 11px 10px 10px;
  font-size: .7rem;
  text-align: center;
}


// --------------
// Button primary
// --------------

.Button--primary {
  position: relative;
  height: 28px;
  padding: 3px 0 5px;
  color: $color-darkPrimary;
  font-size: rem-calc(14);
  @include font-base;
  transition: color .25s ease-in-out,
              padding .25s ease-in-out;

  @media($mq-from-tablet) {
    height: 30px;
    font-size: rem-calc(16);
  }

  span {
    z-index: 1;
    position: relative;
  }

  &:not(.OverrideBackground):hover, #{$keyboard} &:not(.OverrideBackground):focus {
    color: $color-white;
    padding: 3px 8px 5px;

    &:not(.OverrideBackground)::after {
      height: 100%;
      transition: height .25s ease-in-out;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 3px;
    background-color: $color-darkPrimary;
    pointer-events: none;
    transition: height .25s ease-in-out;

    @media($mq-from-tablet) {
      bottom: 2px;
    }
  }

  // Button icon
  &.Button--icon {
    padding-left: 34px;

    &::after {
      width: calc(100% - 34px);
      left: 34px;
      transition: height .25s ease-in-out,
                  width .25s ease-in-out;
    }

    .icon {
      position: absolute;
      width: 26px;
      height: 26px;
      margin-right: 10px;
      top: 3px;
      left: 0;
      transition: color .25s ease-in-out,
                  top .25s ease-in-out;
    }

    &:hover, #{$keyboard} &:focus {
      padding: 3px 8px 5px 42px;

      &::after {
        width: calc(100% - 36px);
      }
      .icon {
        top: 0;
        color: $color-darkPrimary;
      }
    }
  }

  // Button size
  &.Button--large {
    display: inline-table;
    max-width: 300px;
    width: 100%;
    padding: 12px 0 10px 10px;
    @include font-medium($text-transform: uppercase);
    font-size: rem-calc(14);
    letter-spacing: rem-calc(14);

    &::after {
      bottom: 0;
    }
    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      padding: 12px 0 10px 10px;
    }
  }

  // Color
  &.Button--dark {
    &::after {
      background-color: $color-darkPrimary;
    }
    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      color: $color-white;
    }
  }
  &.Button--green {
    color: $color-secondary;

    &::after {
      background-color: $color-secondary;
    }
    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      color: $color-white;

      .icon {
        color: $color-secondary;
        transition: none;
      }
    }
  }
  &.Button--greige {
    color: $color-dark;

    &::after {
      background-color: $color-greige;
    }
    &.Button--active,
    &:not(.OverrideBackground):hover, #{$keyboard} &:not(.OverrideBackground):focus {
      color: $color-white;
    }
  }
  &.Button--white {
    color: $color-white;

    &::after {
      background-color: $color-white;
    }
    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      color: $color-primary;
    }
  }

  // State
  &[disabled] {
    color: $color-gray;
    pointer-events: none;

    &::after {
      background-color: $color-gray;
    }
  }
  // Add to wishlist button
  //&:not(.wishlisted) .icon-heart,
  span:not(.wishlisted) ~ .icon-heart {
    display: none;
  }
}


// ----------------
// Button secondary
// ----------------

.Button--secondary {
  position: relative;
  height: auto;
  padding: 12px 25px 9px 26px;
  color: $color-white;
  background-color: $color-primary;
  border: 0;
  font-size: rem-calc(14);
  text-transform: uppercase;
  letter-spacing: rem-calc(1);
  transition: background-color .25s ease-in-out;

  &[data-wishlist-item] {
    padding: 12px 25px 9px 12px;
  }

  &.Button--active,
  &:hover, #{$keyboard} &:focus {
    background-color: darken($color-primary, 10%);
  }

  // Button size
  &.Button--small {
    padding: 6px 12px 6px 13px;
    @include font-medium($text-transform: uppercase);
    font-size: rem-calc(11);
    letter-spacing: rem-calc(1);
  }
  &.Button--large {
    max-width: 300px;
    width: 100%;
    padding: 12px 5px 10px 15px;
    @include font-medium($text-transform: uppercase);
    font-size: rem-calc(12);
    letter-spacing: rem-calc(7);

    @media($mq-from-tablet) {
      padding: 12px 15px 10px 25px;
      font-size: rem-calc(14);
    }
  }

  // Button icon style
  &.Button--icon {
    .icon-heart {
      margin-right: 10px;
    }
    // Add to wishlist button
    &.wishlisted {
      background-color: $color-red;
      border-color: $color-red;
      color: $color-white;

      .icon-heart {
        color: $color-white;
      }
    }

    .icon-heart {
      @media($mq-phone) {
        &:focus {
          color: #FD3729;
        }
      }
      @media($mq-upto-tablet) {
        &:hover, #{$keyboard} &:focus {
          color: #FD3729;
        }
      }
    }
  }

  // Color
  &.Button--dark {
    background-color: $color-darkPrimary;

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      background-color: darken($color-darkPrimary, 10%);
    }
  }
  &.Button--greige {
    background-color: $color-greige;

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      background-color: darken($color-greige, 10%);
    }
  }
  &.Button--white {
    background-color: $color-white;
    color: $color-darkPrimary;

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      background-color: rgba($color-white, .7);
    }
  }

  // State
  &[disabled] {
    color: $color-white;
    background-color: $color-gray;
    pointer-events: none;
  }
}


// ---------------
// Button tertiary
// ---------------

.Button--tertiary {
  position: relative;
  height: auto;
  padding: 6px 12px 5px 13px;
  @include font-medium;
  color: $color-primary;
  border: 2px solid $color-primary;
  font-size: rem-calc(12);
  letter-spacing: rem-calc(1);
  transition: background-color .25s ease-in-out,
              border-color .25s ease-in-out,
              color .25s ease-in-out;

  &.Button--active,
  &:hover, #{$keyboard} &:focus {
    border-color: $color-darkPrimary;
    color: $color-darkPrimary;
  }

  // Button icon
  &.Button--icon {
    .icon {
      position: absolute;
      width: 12px;
      height: 12px;
      top: 13px;
      right: 13px;

      &-arrow-short {
        transform: rotate(-90deg)
      }
    }
  }

  // Button size
  &.Button--large {
    max-width: 300px;
    width: 100%;
    padding: 12px 15px 10px 25px;
    @include font-base;
    letter-spacing: 0;
  }

  // Color
  &.Button--gray {
    border-color: $color-gray;
    color: $color-secondary;

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      border-color: $color-primary;
    }

    &[aria-expanded="true"] {
      background-color: $color-white;
      border-color: $color-secondary !important;
    }
  }
  &.Button--green {
    border-color: rgba($color-secondary, .65);
    color: $color-secondary;

    &.Button--active,
    &:hover, #{$keyboard} &:focus {
      border-color: $color-secondary;
    }
  }

  // State
  &[disabled] {
    color: $color-gray;
    border-color: $color-gray;
    pointer-events: none;
  }
}



// Modal

.MessageModal[aria-hidden='false'] {
  display: flex !important;
  align-items: center;
  margin: 20vh auto 20px;
  height: 200px;
  flex-direction: column;
  justify-content: space-around;

  @media ($mq-phone) {
    width: calc(100% - 40px);
    min-height: 200px;
    margin: 20vh 20px 20px;
  }
}

.MessageModal a {
  text-decoration: underline;
}

.MessageModal p {
  font-weight: bold;
}


// -------------
// Other options
// -------------

.Button--noHover {
  &:hover, #{$keyboard} &:focus {
    color: inherit;
    border-color: inherit;
    background-color: inherit;
  }
}

.Button--noReaction {
  pointer-events: none;
}
