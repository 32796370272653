// ========
// Homepage
// ========

// Homepage inspirations
.Homepage .Inspirations {
  padding-top: 80px;
}

// Homepage tips exception
.Homepage-tips .NewsSlider .Slider-wrapper {
  @media($mq-phone) {
    margin-bottom: 0;
  }
}
