@mixin printableStyles{
	.ListUrl{
		display : inline;
		word-wrap: anywhere;
	}
	.Wrapper{
		max-width: 100%;
		margin-bottom: 20px;
		padding-bottom: 0 !important;
	}
	.Breadcrumbs{
		display: none;
	}
	.SearchResults{
		background-color: white !important;
	}
	.SearchResults-header{
		padding-top: 30px;
		h1{
			font-size: 1.6rem;
		}
		.SearchResults-categories{
			display: none;
		}
		.SearchResults-headerRight {
			display: none;
		}
		.SearchResults-headerLeft
		{
			.SearchResults-count{
				display: none;
			}
			.SearchResults-text{
				text-transform: capitalize;
			}
		}
	}
	.SearchResults-list{
		padding: 0;
	}
	.Wrapper{
		.SearchFacets{
			display: none;
		}
		.SearchResults-main{
			padding: 0;
		}
		
		.BooksList{
			text-align: center;
			li{
				&::before{
					content: none;
				}
				margin: 0 10px;
				padding: 0;
				width: 22%;
				max-width: unset !important;
				//&:nth-child(8n){
				//	page-break-after: always;
				//	margin-bottom: 100px;
				//}
			}
			.isPush{
				display: none;
			}
			[data-wishlist-item]{
				display: none;
			}
			.SearchResults-show{
				display: none;
			}
		}
	}

	.BookItem{
		margin: 0;
		break-inside: avoid;
		padding-top: 40px;
		page-break-inside: avoid;
		max-width: unset !important;
		margin-bottom: 10px !important;
		
		.BookItem-status{
			display: none;
		}
		.BookItem-text{
			padding: 10px 0;
		}
		.BookItem-ean{
			display: block;
		}
	
		.BookItem-cover{
			img{
				display : block;
				max-height: 220px;
				margin: 0 auto;
			}
			.BookFlip-coverBack{
				display: none;
				
			}
		}
		.BookFlip-button{
			display: none;
		}
	}
	
	.WishList,
	.WishList-bookList{
		padding: 0;
	}
	
	.SearchResults-headerInner{
		display : flex;
		flex-direction: column;
	}
	
	.BookItem-buy,
	.BookItem-deleteFromWishlist,
	.BooksList-button,
	.Newsletter,
	.WishList-warningMessage,
	.SearchResults-tags,
	#btn-pdf-wishlist,
	#btn-email-wishlist,
	.Newsletter-iframe{
		display: none !important;
	}
	
	.BookItem-contributor,
	.BookItem-contributor > span{
		text-decoration: underline;
	}
	
	.BookItem-contributor,
	.BookItem-infos{
		font-size: 0.700rem;
	}
	.Title{
		margin-bottom: 0;
	}
	.BookItem-title{
		font-size: 0.8125rem !important;
	}
}

@media print{
	@include printableStyles;
}
body.print{
	@include printableStyles;
}
