// ==========
// Newsletter
// ==========

.Newsletter {
  position: relative;
  padding: 0 0 45px 0;
  .Newsletter-container{
    position : relative;
    
    #formContainer_gezYPNqpvv2MHVr{
      margin-top : 15px;
      @media all and (max-width : 950px){
        margin-bottom : 50px;
      }
      @media all and (max-width : 700px){
        margin-bottom : 150px;
      }
      .formHeader,.formFooterContainer{
        display : none;
      }
      .formContent{
        form{
          .formTitle,.formParagraph{
            max-width : calc(100% - 455px);
            @media all and (max-width : 950px){
              max-width :unset;
            }
            
          }
          .formText,.formPager {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          .formText{
            right: 200px;
            .formTextInput{
              width : 250px;
              input{
                width: 100%;
                padding: 16px 20px 5px 0;
                background: transparent;
                border: 0;
                color: $color-white;
                font-family: "Poppins", Arial, sans-serif;
                font-weight: 300;
                text-transform: initial;
                font-size: 1rem;
                transition: background 0.25s ease-in-out;
                border-bottom: 1px solid $color-white;
              }
            }
            @media all and (max-width : 950px){
              right: unset;
              bottom : 15px;
              transform: none;
              top : unset;
              width : calc(100% - 200px);
              .formTextInput{
                width : 100%;
              }
            }
            @media all and (max-width : 700px){
              width : calc(100% - 60px);
              bottom : 80px;
            }
          }
          .formPager{
            right: 65px;
            top : 55%;
            a{
              background-color: $color-white;
              color: $color-darkPrimary;
              padding: 16px 30px 13px 31px;
              font-size: 0.75rem;
              font-family: "Poppins", Arial, sans-serif;
              font-weight: 500;
              text-transform: uppercase;
              cursor: pointer;
            }
            @media all and (max-width : 950px){
              bottom : 24px;
              transform: none;
              top : unset;
            }
            @media all and (max-width : 700px){
              width : calc(100% - 60px);
              right: unset;
              a{
                display : block;
                text-align : center;
              }
            }
          }
        }
      }
    }
  }
}

.Newsletter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 30px 15px;
  background-blend-mode: exclusion;
  background-color: $color-darkPrimary;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-white;
  
  @media($mq-tablet) {
    padding: 30px 50px 35px;
  }
  @media($mq-desktop) {
    padding: 30px 70px 35px;
  }

  a {
    position: relative;
    padding: 3px 0 5px;
    color: $color-white;
    font-size: rem-calc(14);
    @include font-base;
    transition: color .25s ease-in-out,
    padding .25s ease-in-out;

    @media($mq-from-tablet) {
      height: 30px;
      font-size: rem-calc(16);
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      background-color: $color-white;
      pointer-events: none;
      transition: height .25s ease-in-out;

      @media($mq-from-tablet) {
        bottom: 2px;
      }
    }
  }
}

.Newsletter-title {
  font-size: rem-calc(18);
  @include font-title;
  margin : 0;
  
  @media($mq-from-tablet) {
    font-size: rem-calc(23);
  }
  
  strong {
    display: block;
    @include font-bold($font-family: $font-family-title);
    font-size: rem-calc(30);
    line-height: 1.2;
    
    @media($mq-from-tablet) {
      font-size: rem-calc(40);
    }
  }
}

.Newsletter-form {
  position: relative;
  display: flex;
  max-width: 500px;
  width: 100%;
  
  @media($mq-phone) {
    flex-direction: column;
    max-width: none;
  }
  @media($mq-from-tablet) {
    border-bottom: 1px solid $color-white;
  }
  
  input[type="email"] {
    width: 100%;
    padding: 16px 20px 5px 0;
    background: transparent;
    border: 0;
    color: $color-white;
    @include font-light;
    font-size: rem-calc(18);
    transition: background .25s ease-in-out;
    
    @media($mq-phone) {
      padding: 14px 20px 5px 0;
      border-bottom: 1px solid $color-white;
      margin-bottom: 10px;
    }
    
    &::placeholder {
      color: $color-white;
    }
    
    &:focus {
      background: rgba($color-white, .15);
    }
  }
  
  input[type="submit"] {
    padding: 16px 30px 13px 31px;
    font-size: rem-calc(12);
    @include font-medium($text-transform: uppercase);
    cursor: pointer;
  }
}
