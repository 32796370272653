// ==========
// Form style
// ==========

// --------------
// Form structure
// --------------

.Form {
  width: 100%;
}

.Form-col {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 35px);
  margin: 0 -20px;

  @media($mq-desktop) {
    width: calc(100% + 60px);
    margin: 0 -30px;
  }
}

.Form-row {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  padding: 8px 20px;
  margin-bottom: 5px;

  @media($mq-tablet) {
    padding: 10px 20px;
  }
  @media($mq-desktop) {
    padding: 10px 30px;
  }

  & > & {
    padding-left: 0;
    padding-right: 0;
  }

  //&--1-2 {
  //  width: 50%;
  //
  //  @media ($mq-phone) {
  //    width:100%;
  //  }
  //}
  //&--1-3 {
  //  width: 33%;
  //
  //  @media ($mq-phone) {
  //    width:100%;
  //  }
  //}
  //&--2-3 {
  //  width: 67%;
  //
  //  @media ($mq-phone) {
  //    width:100%;
  //  }
  //}
  //
  //.Form-col & {
  //  padding: 0 20px 5px;
  //
  //  @media($mq-desktop) {
  //    padding: 0 30px 5px;
  //  }
  //}
}

//.Form-limit input {
//  max-width: 365px;
//}
//
//.Form-inline {
//  display: flex;
//  flex-wrap: wrap;
//}


// --------------------
// Form titles and text
// --------------------

//.Form-title {
//  margin-bottom: 1rem;
//  font-size: .9rem;
//}
//
//.Form-text {
//  display: block;
//  margin-bottom: 6px;
//}
//
//.Form-helper {
//  margin-bottom: 20px;
//}


// -------------
// Form elements
// -------------

fieldset {
  margin-top: 20px;
  margin-bottom: 50px;
}

label {
  display: block;
  font-size: rem-calc(15);

  .Form-inline & {
    display: inline-block;
  }
}

.input,
textarea, select {
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 10px 13px 9px;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba($color-darkGray, 0);
  will-change: border, box-shadow;
  transition: border .25s ease-in-out,
              box-shadow .25s ease-in-out;

  @if ($theme == 'bd') {
    border: 1px solid $color-darkGray;
  } @else {
    border: 1px solid $color-gray;
  }

  &:hover {
    @if ($theme == 'bd') {
      border: 1px solid $color-dark;
    } @else {
      border: 1px solid $color-darkGray;
    }
  }
  &:focus { // No need for keyboard detection
    @if ($theme == 'bd') {
      box-shadow: 0 0 5px $color-dark;
      border: 1px solid $color-dark;
    } @else {
      box-shadow: 0 0 5px $color-darkGray;
      border: 1px solid $color-darkGray;
    }
  }
  &::placeholder {
    //color: rgba($color-dark, .75);
    //@include font-italic;
  }

  //&:invalid {
  //  box-shadow: 0 0 5px $color-red;
  //}
}

textarea {
  min-height: 78px; // Show at least 2 lines with no scrollbar
  resize: vertical; // Allow vertical resize only
  overflow: auto;
}

select {
  position: relative;
  margin-top: 4px;
  background-color: $color-lightGray;
  //background-image: url(asset("images/select-arrow.svg"));
  background-position: center right 10px;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  color: $color-dark;
  letter-spacing: 0.05em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  // Remove default select button on IE
  &::-ms-expand {
    display: none;
  }

  label ~ & {
    margin-top: 0;
  }
}


// ----------------
// Checkbox / Radio
// ----------------

.Form-checkbox,
.Form-radio {

  input {
    position: absolute;
    left: -9999px;
  }

  label {
    position: relative;
    display: inline-block;
    padding: 4px 30px;
    cursor: pointer;
    user-select: none;
  }

  label::before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 18px;
    height: 18px;
    transition: background-color .25s ease-in-out,
                border .25s ease-in-out,
                box-shadow .25s ease-in-out;

    @if ($theme == 'bd') {
      border: 1px solid $color-darkGray;
    } @else {
      border: 1px solid $color-gray;
    }
  }

  input:checked + label::before {
    box-shadow: inset 0 0 0 1px $color-white;

    @if ($theme == 'bd') {
      background-color: $color-dark;
    } @else {
      background-color: $color-darkGray;
    }
  }

  // Simulate the input focus
  input:hover + label::before {
    @if ($theme == 'bd') {
      border: 1px solid $color-dark;
    } @else {
      border: 1px solid $color-darkGray;
    }
  }
  #{$keyboard} & input:focus + label::before {
    @if ($theme == 'bd') {
      border: 1px solid $color-dark;
      box-shadow: inset 0 0 0 1px $color-white, 0 0 5px $color-dark;
    } @else {
      border: 1px solid $color-darkGray;
      box-shadow: inset 0 0 0 1px $color-white, 0 0 5px $color-darkGray;
    }
  }

  // Sublist
  & > ul {
    margin-left: 30px;
    margin-bottom: 5px;
  }

  //// Display variant
  //&--1-2 {
  //  margin-right: -10px;
  //
  //  label {
  //    width: calc(50% - 10px);
  //    margin-right: 10px;
  //    margin-bottom: 10px;
  //
  //    @media($mq-phone) {
  //      width: calc(100% - 10px);
  //    }
  //  }
  //}
  //&--full {
  //  label {
  //    display: block;
  //    width: 100%;
  //    margin-bottom: 10px;
  //  }
  //}

  @media($mq-phone) {
    label {
      display: block;
    }
  }
}

.Form-checkbox {
  label::before {
    border-radius: 2px;
  }
}

.Form-radio {
  label, label::before {
    display: inline-block;
    border-radius: 50%;
  }

  input:checked + label::before {
    box-shadow: inset 0 0 0 3px $color-white;
  }

  // Simulate the input focus
  input:focus + label::before {
    @if ($theme == 'bd') {
      border: 1px solid $color-dark;
      box-shadow: inset 0 0 0 3px $color-white, 0 0 5px $color-dark;
    } @else {
      border: 1px solid $color-darkGray;
      box-shadow: inset 0 0 0 3px $color-white, 0 0 5px $color-darkGray;
    }
  }
}


//  ---------------------
//  Optional group
//  Legend
//  ---------------------

//.Form-optional {
//  display: flex;
//  align-items: baseline;
//  flex-wrap: wrap;
//  margin-bottom: 10px;
//
//  .Form-label {
//    margin-right: 5px;
//    margin-bottom: 0;
//  }
//}
//
//.Form-legend {
//  color: $color-gray;
//  font-size: .7rem;
//  font-style: italic;
//}


//.Form-message {
//  padding: 10px;
//  border: 1px solid #eee;
//
//  &--success {
//    color: $color-green;
//    background-color: rgba($color-green, .1);
//    border: 1px solid $color-green;
//  }
//
//  &--error {
//    color: $color-red;
//    background-color: rgba($color-red, .1);
//    border: 1px solid $color-red;
//  }
//}


//  ---------------------
//  ## RGPD link
//  ---------------------

//.Form-rgpd {
//  display: inline-block;
//  font-size: .8rem;
//
//  a {
//    color: $color-red;
//    text-decoration: none;
//  }
//}


//  ---------------------
//  ## Submit
//  ---------------------

//.Form-submit {
//  display: inline-block;
//  padding: 8px 20px 9px;
//  border: none;
//  color: #fff;
//  font-size: .8rem;
//  text-decoration: none;
//  background-color: #1d1d1b;
//  border-radius: 5px;
//}

//  ------------------------
//  ## Validation and errors
//  ------------------------
//  Selectors are not scoped right, but we're not sure
//  if all error messages are inside a Form class.

//ul.error {
//  background-color: #f2dede;
//}
//ul.error li {
//  color: #b94a48;
//  padding: 2px 10px;
//}
//
//.Form-mainErrors {
//  padding: 10px .75rem;
//  margin-bottom: 15px;
//}
//
//.Form-error {
//  padding: 0 .75rem;
//  margin-top: 4px;
//  color: #E7433C;
//  background-color: rgba(#E7433C, .1);
//  font-size: .6rem;
//  line-height: 1.8;
//}


// ====================
// Awesomplete override
// ====================

.awesomplete {
  width: 100%;

  input[aria-expanded="true"] {
    @if ($theme == 'bd') {
      box-shadow: 0 0 5px $color-dark;
      border: 1px solid $color-dark;
    } @else {
      box-shadow: 0 0 5px $color-darkGray;
      border: 1px solid $color-darkGray;
    }

    & ~ ul {
      @if ($theme == 'bd') {
        border: 1px solid $color-dark;
      } @else {
        border: 1px solid $color-darkGray;
      }
    }
  }

  ul {
    max-height: 200px;
    margin: 0;
    background-color: $color-white;
    border-radius: 4px;
    color: $color-dark;
    overflow-y: auto;

    @if ($theme == 'bd') {
      border: 1px solid $color-darkGray;
    } @else {
      border: 1px solid $color-gray;
    }

    &::before {
      display: none;
    }
  }

  ul > li {
    display: block;
    padding: 11px 12px 9px;

    &:hover {
      background: $color-lightGray;
      color: $color-dark;

      mark {
        background: $color-red;
        color: $color-white;
      }
    }

    &[aria-selected="true"] {
      background: rgba($color-gray, .5);
      color: $color-dark;

      mark {
        background: $color-red;
        color: $color-white;
      }
    }
  }

  mark {
    background: $color-red;
    color: $color-white;
  }
}