/*! Wrapper Component */
// we can put Wrapper in Wrapper to make container

$layout-width: 1240px !default;
$layout-padding: 20px;
$layout-padding-mobile: 20px;

@media screen {

  .Site-wrapper {
    overflow-x: hidden;

    @media ($mq-phone) {
      margin-top: 72px;
    }
  }

  .Wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  // Wrapper sizes
  .Wrapper--small {
    max-width: calc(1060px + #{$layout-padding-mobile} * 2);
    padding-left: $layout-padding-mobile;
    padding-right: $layout-padding-mobile;

    @media($mq-from-tablet) {
      max-width: calc(1060px + #{$layout-padding} * 2);
      padding-left: $layout-padding;
      padding-right: $layout-padding;
    }
  }
  .Wrapper--medium {
    max-width: calc(#{$layout-width} + #{$layout-padding-mobile} * 2);
    padding-left: $layout-padding-mobile;
    padding-right: $layout-padding-mobile;

    @media($mq-from-tablet) {
      max-width: calc(#{$layout-width} + #{$layout-padding} * 2);
      padding-left: $layout-padding;
      padding-right: $layout-padding;
    }
  }
  .Wrapper--large {
    max-width: calc(#{$layout-width} + #{$layout-padding-mobile} * 2);
    padding-left: calc(#{$layout-padding-mobile} + 5px);
    padding-right: calc(#{$layout-padding-mobile} + 5px);

    @media($mq-from-tablet) {
      max-width: calc(#{$layout-width} + #{$layout-padding} * 2);
      padding-left: calc(#{$layout-padding} + 10px);
      padding-right: calc(#{$layout-padding} + 10px);
    }
  }
  .Wrapper--XLarge {
    max-width: calc(#{$layout-width} + #{$layout-padding-mobile} * 2);
    padding-left: calc(#{$layout-padding-mobile} + 5px);
    padding-right: calc(#{$layout-padding-mobile} + 5px);

    @media($mq-tablet) {
      max-width: calc(#{$layout-width} + #{$layout-padding} * 2);
      padding-left: calc(#{$layout-padding} + 40px);
      padding-right: calc(#{$layout-padding} + 40px);
    }
    @media($mq-desktop) {
      max-width: calc(#{$layout-width} + #{$layout-padding} * 2);
      padding-left: calc(#{$layout-padding} + 80px);
      padding-right: calc(#{$layout-padding} + 80px);
    }
  }

  // Wrapper block
  .Wrapper--block {
    max-width: calc(1060px + #{$layout-padding-mobile} * 2);
    padding: 30px $layout-padding-mobile;

    @media($mq-from-tablet) {
      max-width: calc(1060px + #{$layout-padding} * 2);
      padding-left: $layout-padding;
      padding-right: $layout-padding;
    }
    @media($mq-tablet) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
    @media($mq-desktop) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  .Wrapper--blockInner {
    padding: 15px 25px;

    @media($mq-tablet) {
      padding: 30px 45px;
    }
    @media($mq-desktop) {
      padding: 35px 65px;
    }
  }

  // Wrapper padding
  .Wrapper--padding {
    padding-top: 20px;
    padding-bottom: 20px;

    @media($mq-tablet) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @media($mq-desktop) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .Wrapper--paddingMedium {
    padding-top: 20px;
    padding-bottom: 20px;

    @media ($mq-from-tablet) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  .Wrapper--paddingSmall {
    padding-top: 10px;
    padding-bottom: 10px;

    @media ($mq-from-tablet) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .Wrapper--paddingXSmall {
    padding-top: 5px;
    padding-bottom: 5px;

    @media ($mq-from-tablet) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
